import "./ScheduleCallForm.css";
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the correct path to your firebase.js file
import './ProgramForm.css';

const ScheduleCallForm = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    message: ''
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add a document directly under the 'entries' sub-collection
      await addDoc(collection(db, 'formbootcamp', 'SCHEDULECALL', 'entries'), {
        name: form.name,
        email: form.email,
        phone: form.phone,
        date: form.date,
        time: form.time,
        message: form.message
      });
      alert('Form submitted successfully');
      // Reset form
      setForm({
        name: '',
        email: '',
        phone: '',
        date: '',
        time: '',
        message: ''
      });
    } catch (error) {
      console.error('Error adding document:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (

    <div className="scheduleF">
      <section className="scheulfaq">
        <span className='int'> Schedule a Call</span>
        <span className='titleDet'>
          <p></p>
          Please fill out the form below to schedule your call with our admissions team. <br />
          We'll discuss how our program can best support your aspirations.
          <br />We look forward to speaking with you soon!
        </span>

        <form className="schedule" onSubmit={handleSubmit}>
          <label>
            * Full Name:
            <input type="text" name="name" value={form.name} onChange={handleChange} required />
          </label>
          <label>
            * Email Address:
            <input type="email" name="email" value={form.email} onChange={handleChange} required />
          </label>
          <label>
            * Phone Number:
            <input type="tel" name="phone" value={form.phone} onChange={handleChange} required />
          </label>
          <label>
            * Date:
            <input type="date" name="date" value={form.date} onChange={handleChange} required />
          </label>
          <label>
            * Time:
            <input type="time" name="time" value={form.time} onChange={handleChange} required />
          </label>
          <label>
            Tell us about yourself and your goals:
            <textarea name="message" value={form.message} onChange={handleChange} required></textarea>
          </label>
          <button type="submit">Schedule Call</button>
        </form>

      </section>
      <div className="container2">
        <h2 className="faq-headline">Frequently Asked Questions</h2>
        <div className="faq-accordion">
          <div className="faq-item">
            <button className="faq-question">What is the duration of the program?</button>
            <div className="faq-answer">
              <p>The program duration is from 6 weeks <bt></bt>24weeks</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question">What are the prerequisites?</button>
            <div className="faq-answer">
              <p>There are no prerequisites...</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question">What is the job placement rate?</button>
            <div className="faq-answer">
              <p>At FlexAcademic, We're committed to transforming you into a high-tech coding expert. You'll work on multiple projects, enhancing your visibility and enabling you to create your own opportunities. Your final project will involve building an application for a local small business, which you can present to the client. Our goal is to make you both a job hunter and a job creator..</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCallForm;
