import React from 'react';
import '../../class/learningPage.css';
import { useState } from 'react';



const WeekOneDayTwoJava = () => {



        // Quiz state and functions
        const [answers, setAnswers] = useState({});
        const [result, setResult] = useState('');
    
        const correctAnswers = {
            q1: 'b',
            q2: 'b',
            q3: 'c',
            q4: 'b',
            q5: 'a',
            q6: 'b',
            q7: 'b',
            q8: 'c',
            q9: 'c',
            q10: 'b'
        };
    
        const handleChange = (question, value) => {
            setAnswers({
                ...answers,
                [question]: value
            });
        };
    
        const checkAnswers = () => {
            let score = 0;
            const totalQuestions = Object.keys(correctAnswers).length;
    
            for (const [question, answer] of Object.entries(correctAnswers)) {
                if (answers[question] === answer) {
                    score++;
                }
            }
    
            setResult(
                score === totalQuestions
                    ? `Congratulations! You got all ${score} out of ${totalQuestions} correct!`
                    : `You got ${score} out of ${totalQuestions} correct. Try again!`
            );
        };
    
        // Course and navigation state
        const [quizItem, setQuizItem] = useState('course1'); // Default to show video1 and course1
    
        const handleClassClick = (item) => {
            setQuizItem(quizItem === item ? '' : item); // Toggle between the clicked item and hiding all
        };


  return (
    <div className="coursePage">

<div className="TitleC">
 <div>
 <h1>Common Interview Questions</h1>
      <ul>
        <li><strong>Tell me about yourself.</strong> A classic question where you'll summarize your background, skills, and career goals.</li>
        <li><strong>Why do you want to join Revature?</strong> Be prepared to explain your motivation for choosing Revature and its program for career development.</li>
        <li><strong>What do you know about Revature’s training program?</strong> Do research on their software development training and career placement services.</li>
        <li><strong>Describe a technical project you've worked on.</strong> Highlight a project where you applied coding skills, teamwork, and problem-solving.</li>
        <li><strong>What programming languages are you familiar with?</strong> Mention the languages you know well, like Java, Python, or JavaScript, and explain how you’ve used them.</li>
      </ul>

      <h1>Technical/Programming Questions</h1>
      <ul>
        <li><strong>Explain Object-Oriented Programming (OOP).</strong> Be ready to discuss OOP principles: encapsulation, inheritance, polymorphism, and abstraction.</li>
        <li><strong>What is the difference between a class and an object?</strong> A class is a blueprint for creating objects, and an object is an instance of a class.</li>
        <li><strong>Explain the concept of inheritance in Java.</strong> Discuss how one class can inherit properties and methods from another, and give examples.</li>
        <li><strong>What is polymorphism in Java?</strong> Explain how polymorphism allows a method to perform different tasks based on the object that it’s acting upon.</li>
        <li><strong>What are access modifiers in Java?</strong> Discuss public, private, protected, and default access modifiers.</li>
        <li><strong>Describe the difference between == and .equals() in Java.</strong> == compares object references, while .equals() compares values.</li>
        <li><strong>What is an abstract class, and how is it different from an interface?</strong> An abstract class can have defined methods, while an interface only has abstract methods.</li>
        <li><strong>What is a constructor in Java?</strong> Explain how constructors are used to initialize objects and their types (default, parameterized).</li>
        <li><strong>Explain how exceptions are handled in Java.</strong> Discuss try, catch, and finally blocks, as well as throw and throws keywords.</li>
      </ul>

      <h1>Coding Challenges</h1>
      <ul>
        <li><strong>FizzBuzz Problem:</strong> Write a program that prints numbers from 1 to 100, but for multiples of 3, print "Fizz" and for multiples of 5, print "Buzz."</li>
        <li><strong>Palindrome Check:</strong> Write a function to check if a given string is a palindrome.</li>
        <li><strong>Factorial Calculation:</strong> Implement a function that calculates the factorial of a given number using both recursion and iteration.</li>
        <li><strong>Array Manipulation:</strong> Write a program to reverse an array without using extra space.</li>
        <li><strong>String Manipulation:</strong> Write a program to remove duplicate characters from a string.</li>
        <li><strong>Sorting Algorithms:</strong> Implement common sorting algorithms like bubble sort, quicksort, or merge sort.</li>
      </ul>

      <h1>Behavioral Questions</h1>
      <ul>
        <li><strong>Describe a time when you worked in a team to solve a problem.</strong> Use the STAR method (Situation, Task, Action, Result) to structure your response.</li>
        <li><strong>How do you manage tight deadlines?</strong> Explain your approach to time management and staying organized.</li>
        <li><strong>Describe a time when you had to learn a new technology quickly.</strong> Focus on your adaptability and willingness to learn.</li>
        <li><strong>How do you handle constructive criticism?</strong> Provide an example of how you used feedback to improve your performance.</li>
      </ul>

      <h1>OOP Concepts in Java and C#</h1>
      <h2>Encapsulation</h2>
      <pre>
        <code>
          {/* Java Example */}
          {`public class Car {
    private String model;
    private int year;

    public Car(String model, int year) {
        this.model = model;
        this.year = year;
    }

    public String getModel() {
        return model;
    }

    public void setModel(String model) {
        this.model = model;
    }

    public int getYear() {
        return year;
    }

    public void setYear(int year) {
        this.year = year;
    }

    public void displayInfo() {
        System.out.println("Car model: " + model + ", Year: " + year);
    }
}`}
          {/* C# Example */}
          {`public class Car {
    public string Model { get; set; }
    public int Year { get; set; }

    public Car(string model, int year) {
        Model = model;
        Year = year;
    }
}`}
        </code>
      </pre>

      <h2>Inheritance</h2>
      <pre>
        <code>
          {/* Java Example */}
          {`class Animal {
    void sound() {
        System.out.println("Animal makes a sound");
    }
}

class Dog extends Animal {
    @Override
    void sound() {
        System.out.println("Dog barks");
    }
}

public class Main {
    public static void main(String[] args) {
        Animal myDog = new Dog();
        myDog.sound(); // Output: Dog barks
    }
}`}
          {/* C# Example */}
          {`class Animal {
    public virtual void Sound() {
        Console.WriteLine("Animal makes a sound");
    }
}

class Dog : Animal {
    public override void Sound() {
        Console.WriteLine("Dog barks");
    }
}

class Program {
    static void Main(string[] args) {
        Animal myDog = new Dog();
        myDog.Sound(); // Output: Dog barks
    }
}`}
        </code>
      </pre>

      <h2>Polymorphism</h2>
      <pre>
        <code>
          {/* Java Example */}
          {`class Calculator {
    int add(int a, int b) {
        return a + b;
    }

    double add(double a, double b) {
        return a + b;
    }
}

class Parent {
    void show() {
        System.out.println("Parent method");
    }
}

class Child extends Parent {
    @Override
    void show() {
        System.out.println("Child method");
    }
}`}
          {/* C# Example */}
          {`class Program {
    static void Main(string[] args) {
        Calculator calc = new Calculator();
        Console.WriteLine(calc.Add(5, 10)); // Method Overloading
        Console.WriteLine(calc.Add(5.5, 10.5));
    }
}

class Parent {
    public virtual void Show() {
        Console.WriteLine("Parent method");
    }
}

class Child : Parent {
    public override void Show() {
        Console.WriteLine("Child method");
    }
}`}
        </code>
      </pre>

      <h2>Abstraction</h2>
      <pre>
        <code>
          {/* Java Example */}
          {`abstract class Shape {
    abstract void draw();
}

class Circle extends Shape {
    void draw() {
        System.out.println("Drawing Circle");
    }
}`}
          {/* C# Example */}
          {`abstract class Shape {
    public abstract void Draw();
}

class Circle : Shape {
    public override void Draw() {
        Console.WriteLine("Drawing Circle");
    }
}`}
        </code>
      </pre>

      <h2>Encapsulation</h2>
      <pre>
        <code>
          {/* Java Example */}
          {`public class Car {
    private String model;
    private int year;

    public Car(String model, int year) {
        this.model = model;
        this.year = year;
    }

    public String getModel() {
        return model;
    }

    public void setModel(String model) {
        this.model = model;
    }

    public int getYear() {
        return year;
    }

    public void setYear(int year) {
        this.year = year;
    }

    public void displayInfo() {
        System.out.println("Car model: " + model + ", Year: " + year);
    }
}`}
          {/* C# Example */}
          {`public class Car {
    public string Model { get; set; }
    public int Year { get; set; }

    public Car(string model, int year) {
        Model = model;
        Year = year;
    }
}`}
        </code>
      </pre>

    </div>
</div>
      <div className="quizBar">
                <span>Daily task</span>
                <ul>
                    <li onClick={() => handleClassClick('course1')}>Course 1</li>
                    <li onClick={() => handleClassClick('exercice1')}>Exercice 1</li>
                    <li onClick={() => handleClassClick('course2')}>Course 2</li>
                    <li onClick={() => handleClassClick('exercice2')}>Exercice 2</li>
                    <li onClick={() => handleClassClick('other')}>Other</li>
                </ul>
            </div>



    </div>
  );
};

export default WeekOneDayTwoJava;
