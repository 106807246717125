import React, { useState } from 'react';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the correct path to your firebase.js file
import { useNavigate } from 'react-router-dom';
import "../class/Login.css";

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Check if admin exists with given credentials
      const q = query(
        collection(db, 'formbootcamp', 'adminAuth', 'adminAuthentitcation'),
        where('username', '==', username),
        where('password', '==', password)
      );
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        // Redirect to admin dashboard
        alert('Valid credentials');
        navigate('/admin');
      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Error checking credentials:', error);
      alert('Error during login. Please try again.');
    }
  };

  return (
    <div className="login">
      <h2>Admin Login</h2>
      <form className="formLogin" onSubmit={handleSubmit}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;