import React from 'react';
import '../../class/learningPage.css';
import { useState } from 'react';



const WeekOneDayOneJava = () => {



        // Quiz state and functions
        const [answers, setAnswers] = useState({});
        const [result, setResult] = useState('');
    
        const correctAnswers = {
            q1: 'b',
            q2: 'b',
            q3: 'c',
            q4: 'b',
            q5: 'a',
            q6: 'b',
            q7: 'b',
            q8: 'c',
            q9: 'c',
            q10: 'b'
        };
    
        const handleChange = (question, value) => {
            setAnswers({
                ...answers,
                [question]: value
            });
        };
    
        const checkAnswers = () => {
            let score = 0;
            const totalQuestions = Object.keys(correctAnswers).length;
    
            for (const [question, answer] of Object.entries(correctAnswers)) {
                if (answers[question] === answer) {
                    score++;
                }
            }
    
            setResult(
                score === totalQuestions
                    ? `Congratulations! You got all ${score} out of ${totalQuestions} correct!`
                    : `You got ${score} out of ${totalQuestions} correct. Try again!`
            );
        };
    
        // Course and navigation state
        const [quizItem, setQuizItem] = useState('course1'); // Default to show video1 and course1
    
        const handleClassClick = (item) => {
            setQuizItem(quizItem === item ? '' : item); // Toggle between the clicked item and hiding all
        };


  return (
    <div className="coursePage">

<div className="TitleC">

      <h1>Week 1: Introduction to Java and Basic Syntax</h1>
      
      <section>
        <h2>Session 1: Introduction to Java</h2>
        
        <h3>1. What is Java?</h3>
        <p>
          Java is a high-level, object-oriented programming language developed by Sun Microsystems (now owned by Oracle) in 1995. 
          It is designed to be platform-independent, meaning that Java programs can run on any device that has a Java Virtual Machine (JVM). 
          Java is widely used in various applications, from web development to mobile apps and large-scale enterprise systems.
        </p>
        
        <h4>Key Features of Java:</h4>
        <ul>
          <li><strong>Platform Independence:</strong> Java programs are compiled into bytecode, which can be executed on any device with a JVM, making Java "write once, run anywhere."</li>
          <li><strong>Object-Oriented:</strong> Java is based on the principles of object-oriented programming (OOP), promoting concepts like inheritance, polymorphism, encapsulation, and abstraction.</li>
          <li><strong>Robust and Secure:</strong> Java provides strong memory management, exception handling, and a rich set of APIs that make it a reliable choice for building secure applications.</li>
          <li><strong>Multithreading:</strong> Java supports multithreading, allowing concurrent execution of two or more threads for efficient performance.</li>
        </ul>
        
        <h3>2. Setting up the Development Environment (JDK, IDE, etc.)</h3>
        <h4>Installing the Java Development Kit (JDK):</h4>
        <p>The Java Development Kit (JDK) is essential for developing Java applications. It includes the Java Runtime Environment (JRE), the Java compiler (javac), and other tools needed to develop Java programs.</p>
        
        <h5>Steps to Install:</h5>
        <ul>
          <li>Download the latest JDK from the official Oracle website.</li>
          <li>Run the installer and follow the on-screen instructions.</li>
          <li>Set up the JAVA_HOME environment variable (optional but recommended) to point to the JDK installation directory.</li>
        </ul>
        
        <h4>Choosing an Integrated Development Environment (IDE):</h4>
        <p>An IDE provides a user-friendly interface to write, debug, and compile Java code. Popular IDEs for Java include:</p>
        <ul>
          <li>IntelliJ IDEA (Community Edition is free and powerful)</li>
          <li>Eclipse (Free and widely used in the industry)</li>
          <li>NetBeans (Free and supports multiple languages)</li>
        </ul>
        
        <h5>Steps to Set Up an IDE:</h5>
        <ul>
          <li>Download and install your preferred IDE.</li>
          <li>Configure the IDE to recognize the JDK (usually done automatically during the first run).</li>
          <li>Familiarize yourself with the IDE's interface, including the project explorer, code editor, and console output.</li>
        </ul>
        
        <h3>3. Writing Your First Java Program (HelloWorld.java)</h3>
        <p>The goal of this section is to write, compile, and run your first Java program: HelloWorld.java.</p>
        
        <h4>Step-by-Step Guide:</h4>
        <ol>
          <li>Create a New Java Project:
            <ul>
              <li>Open your IDE and create a new Java project named "HelloWorldProject."</li>
            </ul>
          </li>
          <li>Write the HelloWorld Class:
            <ul>
              <li>In your project, create a new Java class named HelloWorld.</li>
              <li>Write the following code in the HelloWorld.java file:</li>
              <pre>
{`public class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, World!");
    }
}`}
              </pre>
            </ul>
          </li>
        </ol>
        
        <h4>Explanation of the Code:</h4>
        <ul>
          <li><strong>public class HelloWorld:</strong> Defines a public class named HelloWorld.</li>
          <li><strong>public static void main(String[] args):</strong> The main method is the entry point of the Java program. The args parameter is used to accept command-line arguments.</li>
          <li><strong>System.out.println("Hello, World!");:</strong> Prints "Hello, World!" to the console.</li>
        </ul>
        
        <h4>Compile and Run the Program:</h4>
        <ul>
          <li><strong>Compile:</strong> The IDE usually handles compilation automatically. If you're using the command line, navigate to the directory containing HelloWorld.java and run:
            <pre>javac HelloWorld.java</pre>
          </li>
          <li><strong>Run:</strong> Execute the compiled bytecode with the following command:
            <pre>java HelloWorld</pre>
          </li>
        </ul>
        
        <h3>4. Understanding the Structure of a Java Program</h3>
        <pre>
{`public class test1 {
    public static void main(String[] args) {
        System.out.println("hello world!");
    }
}`}
        </pre>
        <ul>
          <li><strong>public class test1:</strong> The class test1 is public and can be accessed from other classes and packages.</li>
          <li><strong>public static void main(String[] args):</strong> The main method is the entry point and is public, static, and void.</li>
          <li><strong>System.out.println("hello world!");:</strong> Prints "hello world!" to the console.</li>
        </ul>
        
        <h4>Correct Class Name:</h4>
        <p>Since your file is named test.java, the class definition should be:</p>
        <pre>
{`public class test {
    public static void main(String[] args) {
        System.out.println("hello world!");
    }
}`}
        </pre>
        
        <h4>Key Points:</h4>
        <ul>
          <li><strong>File Name and Public Class Name:</strong> The filename test.java must match the name of the public class in the file.</li>
          <li><strong>Class Declaration:</strong> The public keyword means the class is accessible from outside its package.</li>
        </ul>
        
        <h3>Declaring Variables Inside vs. Outside the main Method in Java</h3>
        
        <h4>1. Variables Declared Inside the main Method</h4>
        <ul>
          <li><strong>Scope:</strong> Local to the main method.</li>
          <li><strong>Lifetime:</strong> Exists only during method execution.</li>
          <li><strong>Accessibility:</strong> Only accessible within the main method.</li>
        </ul>
        <pre>
{`public class CarTest {
    public static void main(String[] args) {
        String make = "Honda";
        String model = "Civic";
        int year = 2022;
        String color = "Red";

        System.out.println("Car Make: " + make);
        System.out.println("Car Model: " + model);
        System.out.println("Car Year: " + year);
        System.out.println("Car Color: " + color);
    }
}`}
        </pre>
        
        <h4>2. Variables Declared Outside the main Method (at Class Level)</h4>
        <ul>
          <li><strong>Scope:</strong> Accessible by all methods in the class.</li>
          <li><strong>Lifetime:</strong> Exists as long as the object exists.</li>
          <li><strong>Accessibility:</strong> Accessible by any method in the class.</li>
        </ul>
        <pre>
{`public class CarTest {
    String make = "Honda";
    String model = "Civic";
    int year = 2022;
    String color = "Red";

    public static void main(String[] args) {
        CarTest myCar = new CarTest();

        System.out.println("Car Make: " + myCar.make);
        System.out.println("Car Model: " + myCar.model);
        System.out.println("Car Year: " + myCar.year);
        System.out.println("Car Color: " + myCar.color);
    }
}`}
        </pre>
        
        <h4>Key Differences</h4>
        <ul>
          <li><strong>Scope:</strong>
            <ul>
              <li>Inside main: The scope is limited to the main method.</li>
              <li>Outside main: The scope is the entire class.</li>
            </ul>
          </li>
          <li><strong>Lifetime:</strong>
            <ul>
              <li>Inside main: Variables are created and destroyed within the main method.</li>
              <li>Outside main: Variables last as long as the object (instance of the class) exists.</li>
            </ul>
          </li>
          <li><strong>Accessibility:</strong>
            <ul>
              <li>Inside main: Only the main method can access these variables.</li>
              <li>Outside main: All methods within the class can access these variables.</li>
            </ul>
          </li>
        </ul>
      </section>
<div>
        
        <h2>Java File and Public Class:</h2>
        <p>
          <strong>Public Class:</strong> In Java, a file can contain one public class. 
          The name of this class must match the name of the file. For example, if your 
          file is named <code>JavaFileName.java</code>, your public class should be named 
          <code>JavaFileName</code>. This class is the main blueprint for creating objects 
          or performing actions in that file.
        </p>
        <p>
          <strong>Code Inside the Class:</strong> All your methods, including the main method, 
          will be written inside this class.
        </p>

        <h2>Main Method:</h2>
        <p>
          <strong>public static void main(String[] args):</strong> This is the entry point 
          of a Java application. It’s the method that gets called when you run your Java program.
        </p>
        <ul>
          <li><strong>public:</strong> This means that the main method can be accessed from 
            outside the class. It's necessary for the Java Virtual Machine (JVM) to access this 
            method to start the program.
          </li>
          <li><strong>static:</strong> This means that the main method belongs to the class itself, 
            not to any instance of the class. You don’t need to create an object of the class 
            to call this method; the JVM can call it directly.
          </li>
          <li><strong>void:</strong> This means that the main method doesn’t return any value. 
            It just performs tasks, like printing to the console.
          </li>
          <li><strong>String[] args:</strong> This is an array of String objects that can be used 
            to pass command-line arguments to your program.
          </li>
        </ul>

        <h2>Private Static Main:</h2>
        <p>
          If you declare the main method as <code>private static void main(String[] args)</code>, 
          it means that it can only be accessed from within the same class. While this doesn’t 
          affect the functionality of the method itself, it’s not standard practice for the entry 
          point of a Java application because the JVM needs to access main from outside the class 
          to start the application.
        </p>

        <h2>Clarified Example:</h2>
        <pre>
          <code>
{`public class JavaFileName {
    public static void main(String[] args) {
        // This is the entry point of the program.
        // It is public so the JVM can access it.
        // It is static so it can be called without creating an object.
        // It is void because it does not return a value.
        
        System.out.println("Hello, World!");
    }
}`}
          </code>
        </pre>
        <p>
          <strong>public class JavaFileName:</strong> This is the public class and must match 
          the file name.
        </p>
        <p>
          <strong>public static void main(String[] args):</strong> This is the main method that 
          the JVM calls to start your program. It’s public, static, and void, meaning it can be 
          accessed from anywhere, doesn’t require an instance of the class, and doesn’t return a value.
        </p>

        <h2>Declaring Variables and Methods in Java:</h2>
        <h3>Variables and Methods Inside the main Method:</h3>
        <pre>
          <code>
{`public static void main(String[] args) {
    int x = 10;
    String name = "Papy";
    System.out.println(x);
    System.out.println(name);
}`}
          </code>
        </pre>
        <p>
          Variables declared inside the main method are local to that method. They are only 
          accessible within the main method and are created and destroyed each time the method 
          is executed.
        </p>

        <h3>Variables and Methods Outside the main Method:</h3>
        <h4>Instance Variables:</h4>
        <pre>
          <code>
{`public class JavaFileName {
    int x = 10; // Instance variable
    String name = "Papy"; // Instance variable

    public static void main(String[] args) {
        JavaFileName obj = new JavaFileName(); // Creating an object of the class
        System.out.println(obj.x); // Accessing instance variable
        System.out.println(obj.name); // Accessing instance variable
    }
}`}
          </code>
        </pre>
        <p>
          Variables declared outside the main method but inside the class are instance variables 
          (or fields). These variables are accessible by all methods in the class. They are created 
          when an object of the class is created and destroyed when the object is destroyed.
        </p>

        <h4>Static Variables:</h4>
        <pre>
          <code>
{`public class JavaFileName {
    static int x = 10; // Static variable
    static String name = "Papy"; // Static variable

    public static void main(String[] args) {
        System.out.println(x); // Accessing static variable
        System.out.println(name); // Accessing static variable
    }
}`}
          </code>
        </pre>
        <p>
          Variables declared with the static keyword are class variables. They are shared among 
          all instances of the class. You can access them without creating an object of the class.
        </p>

        <h4>Instance Methods:</h4>
        <pre>
          <code>
{`public class JavaFileName {
    int x = 10;

    public static void main(String[] args) {
        JavaFileName obj = new JavaFileName(); // Creating an object of the class
        int result = obj.calculateAandB(2, 3); // Calling instance method
        System.out.println(result);
    }

    int calculateAandB(int a, int b) {
        return a + b; // Instance method
    }
}`}
          </code>
        </pre>
        <p>
          Methods declared outside the main method but inside the class are known as instance 
          methods. They can be called on objects of the class.
        </p>

        <h4>Static Methods:</h4>
        <pre>
          <code>
{`public class JavaFileName {
    static int calculateAandB(int a, int b) {
        return a + b; // Static method
    }

    public static void main(String[] args) {
        int result = calculateAandB(2, 3); // Calling static method
        System.out.println(result);
    }
}`}
          </code>
        </pre>
        <p>
          Methods declared with the static keyword belong to the class rather than to any instance 
          of the class. They can be called without creating an object of the class.
        </p>

        <h2>Key Points:</h2>
        <ul>
          <li><strong>Instance Variables and Methods:</strong> These can be accessed using objects 
            of the class. They can be used across multiple methods within the same class.
          </li>
          <li><strong>Static Variables and Methods:</strong> These belong to the class itself and 
            can be accessed without creating an object. They are shared among all instances of the class.
          </li>
          <li><strong>Scope:</strong> Using instance variables and methods allows you to share data 
            and functionality across multiple methods in the class. Static variables and methods are 
            used when you need functionality that is common to all instances of the class.
          </li>
        </ul>

        <h2>Recommendations:</h2>
        <ul>
          <li>Use instance variables and methods when you need to maintain state or functionality 
            specific to individual objects.
          </li>
          <li>Use static variables and methods when you need functionality or data that should be 
            common across all instances of the class or that doesn’t require an instance.
          </li>
        </ul>

        <p>Feel free to ask if you have more questions or need further clarification!</p>
      </div>

       <div>
        <h1>Java Programming Basics</h1>

        <h2>Session 2: Variables and Data Types</h2>

        <h3>Primitive Data Types</h3>
        <p>Java provides several primitive data types to store data:</p>
        <ul>
          <li><strong>int:</strong> Stores integer values. Example: <code>int age = 25;</code></li>
          <li><strong>double:</strong> Stores floating-point numbers with double precision. Example: <code>double salary = 55000.50;</code></li>
          <li><strong>char:</strong> Stores a single character. Example: <code>char grade = 'A';</code></li>
          <li><strong>boolean:</strong> Stores true or false values. Example: <code>boolean isActive = true;</code></li>
        </ul>

        <h3>Declaring and Initializing Variables</h3>
        <p>In Java, you declare a variable by specifying its type followed by its name. You can initialize it at the time of declaration or later.</p>
        <pre>
          <code>
{`public class Course {
    public static void main(String[] args) {
        // Declaration and initialization
        int number = 10;
        double price = 99.99;
        char letter = 'X';
        boolean isAvailable = false;
        
        // Output the variables
        System.out.println("Number: " + number);
        System.out.println("Price: " + price);
        System.out.println("Letter: " + letter);
        System.out.println("Available: " + isAvailable);
    }
}`}
          </code>
        </pre>

        <h3>Basic Operators</h3>
        <p>Basic operators are used to perform operations on variables and values:</p>
        <ul>
          <li><strong>Arithmetic Operators:</strong> Perform mathematical operations. Examples: <code>+</code> (addition), <code>-</code> (subtraction), <code>*</code> (multiplication), <code>/</code> (division)</li>
          <li><strong>Relational Operators:</strong> Compare two values. Examples: <code>==</code> (equal to), <code>!=</code> (not equal to), <code>&gt;</code> (greater than), <code>&lt;</code> (less than)</li>
          <li><strong>Logical Operators:</strong> Combine multiple conditions. Examples: <code>&&</code> (AND), <code>||</code> (OR), <code>!</code> (NOT)</li>
        </ul>
        <pre>
          <code>
{`public class Course {
    public static void main(String[] args) {
        int a = 10, b = 5;
        double result;
        
        // Arithmetic Operators
        result = a + b;
        System.out.println("Sum: " + result);
        
        result = a - b;
        System.out.println("Difference: " + result);
        
        result = a * b;
        System.out.println("Product: " + result);
        
        result = a / b;
        System.out.println("Quotient: " + result);
        
        // Relational Operators
        System.out.println("a > b: " + (a > b));
        System.out.println("a == b: " + (a == b));
        
        // Logical Operators
        boolean condition1 = true, condition2 = false;
        System.out.println("condition1 && condition2: " + (condition1 && condition2));
        System.out.println("condition1 || condition2: " + (condition1 || condition2));
    }
}`}
          </code>
        </pre>

        <h3>Type Conversion and Casting</h3>
        <p>Type conversion changes one data type to another:</p>
        <ul>
          <li><strong>Implicit Conversion:</strong> Automatic conversion by Java. Example: <code>int to double</code></li>
          <li><strong>Explicit Casting:</strong> Manual conversion. Example: <code>double to int</code> with <code>(int)</code></li>
        </ul>
        <pre>
          <code>
{`public class Course {
    public static void main(String[] args) {
        // Implicit conversion
        int num = 10;
        double result = num; // int to double
        System.out.println("Implicit Conversion: " + result);
        
        // Explicit casting
        double price = 99.99;
        int roundedPrice = (int) price; // double to int
        System.out.println("Explicit Casting: " + roundedPrice);
    }
}`}
          </code>
        </pre>

        <h2>Session 3: Control Structures</h2>

        <h3>Conditional Statements</h3>
        <p>Conditional statements allow you to execute code based on certain conditions:</p>
        <ul>
          <li><strong>if:</strong> Executes a block of code if its condition is true.</li>
          <li><strong>else if:</strong> Provides an alternative condition if the previous <code>if</code> condition is false.</li>
          <li><strong>else:</strong> Executes a block of code if all previous conditions are false.</li>
          <li><strong>switch:</strong> Allows you to choose from multiple options based on the value of a variable.</li>
        </ul>
        <pre>
          <code>
{`public class Course {
    public static void main(String[] args) {
        int number = 10;

        // if-else statements
        if (number > 0) {
            System.out.println("Positive number");
        } else if (number < 0) {
            System.out.println("Negative number");
        } else {
            System.out.println("Zero");
        }

        // switch statement
        char grade = 'B';
        switch (grade) {
            case 'A':
                System.out.println("Excellent");
                break;
            case 'B':
                System.out.println("Good");
                break;
            case 'C':
                System.out.println("Fair");
                break;
            default:
                System.out.println("Invalid grade");
        }
    }
}`}
          </code>
        </pre>

        <h3>Loops</h3>
        <p>Loops are used to execute a block of code repeatedly:</p>
        <ul>
          <li><strong>for:</strong> Executes a block of code a specific number of times.</li>
          <li><strong>while:</strong> Executes a block of code as long as its condition is true.</li>
          <li><strong>do-while:</strong> Executes a block of code at least once and then continues as long as its condition is true.</li>
        </ul>
        <pre>
          <code>
{`public class Course {
    public static void main(String[] args) {
        // For loop
        for (int i = 0; i < 5; i++) {
            System.out.println("For loop iteration: " + i);
        }

        // While loop
        int j = 0;
        while (j < 5) {
            System.out.println("While loop iteration: " + j);
            j++;
        }

        // Do-while loop
        int k = 0;
        do {
            System.out.println("Do-while loop iteration: " + k);
            k++;
        } while (k < 5);
    }
}`}
          </code>
        </pre>

        <h3>Break and Continue Statements</h3>
        <p>Control the flow of loops using <code>break</code> and <code>continue</code>:</p>
        <ul>
          <li><strong>break:</strong> Exits the loop immediately.</li>
          <li><strong>continue:</strong> Skips the current iteration and proceeds to the next iteration of the loop.</li>
        </ul>
        <pre>
          <code>
{`public class Course {
    public static void main(String[] args) {
        // Example of break
        for (int i = 0; i < 10; i++) {
            if (i == 5) {
                break; // Exit the loop when i equals 5
            }
            System.out.println("Break example: " + i);
        }

        // Example of continue
        for (int j = 0; j < 10; j++) {
            if (j % 2 == 0) {
                continue; // Skip even numbers
            }
            System.out.println("Continue example: " + j);
        }
    }
}`}
          </code>
        </pre>
      </div>
<div className="lesson-container">
            <h1>Lesson 3: Usage of Java Keywords</h1>
            <p>In this lesson, we'll explore the usage of some key Java keywords, specifically <code>this</code>, <code>new</code>, <code>super</code>, <code>static</code>, and <code>final</code>. Each keyword has specific purposes and helps manage various aspects of Java programming.</p>
            
            <h2>1. Usage of <code>this</code> Keyword</h2>
            <p>The <code>this</code> keyword in Java is a reference to the current object. It is used to refer to the current instance of a class and has several important uses:</p>

            <h3>Referencing Current Class Instance Variables:</h3>
            <p>When instance variables and parameters have the same name, <code>this</code> helps to distinguish between them.</p>
            <pre>
                <code>
{`class Student {
    int rollno;
    String name;
    float fee;

    Student(int rollno, String name, float fee) {
        this.rollno = rollno; // Refers to instance variable rollno
        this.name = name;     // Refers to instance variable name
        this.fee = fee;       // Refers to instance variable fee
    }

    void display() {
        System.out.println(rollno + " " + name + " " + fee);
    }
}

public class TestThis2 {
    public static void main(String[] args) {
        Student s1 = new Student(111, "ankit", 5000f);
        Student s2 = new Student(112, "sumit", 6000f);
        s1.display();
        s2.display();
    }
}`}
                </code>
            </pre>

            <h3>Invoking Current Class Method:</h3>
            <p>Methods of the current class can be invoked using <code>this</code>, though it is optional.</p>
            <pre>
                <code>
{`class A {
    void m() {
        System.out.println("hello m");
    }

    void n() {
        System.out.println("hello n");
        this.m();  // Calls method m() of the current class
    }
}

public class TestThis4 {
    public static void main(String[] args) {
        A a = new A();
        a.n();
    }
}`}
                </code>
            </pre>

            <h3>Invoking Current Class Constructor:</h3>
            <p>You can use <code>this()</code> to call another constructor within the same class, useful for constructor chaining.</p>
            <pre>
                <code>
{`class A {
    A() {
        System.out.println("hello a");
    }

    A(int x) {
        this();  // Calls no-argument constructor
        System.out.println(x);
    }
}

public class TestThis5 {
    public static void main(String[] args) {
        A a = new A(10);
    }
}`}
                </code>
            </pre>

            <h3>Passing <code>this</code> as an Argument:</h3>
            <p>The <code>this</code> reference can be passed to other methods or constructors.</p>

            <h3>Returning <code>this</code> from a Method:</h3>
            <p>Methods can return the current instance using <code>this</code>.</p>

            <h2>2. Usage of <code>new</code> Keyword</h2>
            <p>The <code>new</code> keyword is used to create objects. It allocates memory for a new object and initializes it using the class constructor.</p>

            <h3>Declaration:</h3>
            <pre>
                <code>
{`Student anil; // Declaration, no object is created yet`}
                </code>
            </pre>

            <h3>Instantiation and Initialization:</h3>
            <pre>
                <code>
{`anil = new Student(); // Instantiation and initialization`}
                </code>
            </pre>

            <h3>Example:</h3>
            <pre>
                <code>
{`class Student {
    // Constructor
    Student() {
        System.out.println("Student object created");
    }
}

public class TestNewKeyword {
    public static void main(String[] args) {
        Student anil = new Student(); // Creates a new Student object
    }
}`}
                </code>
            </pre>

            <h2>3. Usage of <code>super</code> Keyword</h2>
            <p>The <code>super</code> keyword refers to the immediate parent class. It can be used to access parent class methods, variables, and constructors.</p>

            <h3>Referencing Parent Class Variables:</h3>
            <pre>
                <code>
{`class Animal {
    String color = "white";
}

class Dog extends Animal {
    String color = "black";

    void printColor() {
        System.out.println(color);           // Prints Dog's color
        System.out.println(super.color);     // Prints Animal's color
    }
}

public class TestSuper1 {
    public static void main(String[] args) {
        Dog d = new Dog();
        d.printColor();
    }
}`}
                </code>
            </pre>

            <h3>Invoking Parent Class Methods:</h3>
            <pre>
                <code>
{`class Animal {
    void eat() {
        System.out.println("eating...");
    }
}

class Dog extends Animal {
    void eat() {
        System.out.println("eating bread...");
    }

    void work() {
        super.eat();  // Calls eat() from Animal class
        System.out.println("barking...");
    }
}

public class TestSuper2 {
    public static void main(String[] args) {
        Dog d = new Dog();
        d.work();
    }
}`}
                </code>
            </pre>

            <h3>Invoking Parent Class Constructor:</h3>
            <p>The <code>super()</code> call must be the first statement in the subclass constructor.</p>
            <pre>
                <code>
{`class Animal {
    Animal() {
        System.out.println("animal is created");
    }
}

class Dog extends Animal {
    Dog() {
        super();  // Calls constructor of Animal class
        System.out.println("dog is created");
    }
}

public class TestSuper3 {
    public static void main(String[] args) {
        Dog d = new Dog();
    }
}`}
                </code>
            </pre>

            <h2>4. Usage of <code>static</code> Keyword</h2>
            <p>The <code>static</code> keyword is used to define class-level variables and methods that are shared among all instances of the class.</p>

            <h3>Static Variables:</h3>
            <pre>
                <code>
{`class Student {
    int rollno;
    String name;
    static String college = "ITS";  // Static variable

    Student(int r, String n) {
        rollno = r;
        name = n;
    }

    void display() {
        System.out.println(rollno + " " + name + " " + college);
    }
}

public class TestStaticVariable1 {
    public static void main(String[] args) {
        Student s1 = new Student(111, "Karan");
        Student s2 = new Student(222, "Aryan");
        s1.display();
        s2.display();
    }
}`}
                </code>
            </pre>

            <h3>Static Methods, Blocks, and Nested Classes:</h3>
            <p>Static methods can be called without creating an instance of the class. Static blocks are used for static initializations. Static nested classes do not have access to instance variables.</p>

            <h2>5. Usage of <code>final</code> Keyword</h2>
            <p>The <code>final</code> keyword restricts modification. It can be applied to variables, methods, and classes.</p>

            <h3>Final Variables:</h3>
            <pre>
                <code>
{`class Bike {
    final int speedlimit = 90; // Final variable

    void run() {
        // speedlimit = 400; // Compile-time error: cannot assign a value to final variable
    }
}`}
                </code>
            </pre>

            <h3>Final Methods:</h3>
            <pre>
                <code>
{`class Bike {
    final void run() {
        System.out.println("running");
    }
}

class Honda extends Bike {
    // void run() { // Compile-time error: cannot override final method
    //    System.out.println("running safely with 100kmph");
    // }
}`}
                </code>
            </pre>

            <h3>Final Classes:</h3>
            <pre>
                <code>
{`final class Bike {
    // Class cannot be extended
}

class Honda extends Bike { // Compile-time error: cannot subclass final class
    void run() {
        System.out.println("running safely with 100kmph");
    }
}`}
                </code>
            </pre>
        </div>
</div>
      <div className="quizBar">
                <span>Daily task</span>
                <ul>
                    <li onClick={() => handleClassClick('course1')}>Course 1</li>
                    <li onClick={() => handleClassClick('exercice1')}>Exercice 1</li>
                    <li onClick={() => handleClassClick('course2')}>Course 2</li>
                    <li onClick={() => handleClassClick('exercice2')}>Exercice 2</li>
                    <li onClick={() => handleClassClick('other')}>Other</li>
                </ul>
            </div>



    </div>
  );
};

export default WeekOneDayOneJava;
