import React from 'react'
import './styles.css'

const WelcomWF =()=>{


  return (
    <div>
 <div className='welcomeText'>

<div class="videoContent">
  <iframe className='iframe00' src="https://www.youtube.com/embed/8hovjjvDK0A?si=_iK-PqWogBvhnfTA" title="YouTube video player"
    frameborder="0" allow="accelerometer; autoplay; 
  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

</div>
<div class="titleText">


  <h3>Monday to Friday</h3>
</div>



<div class="container">
  <h1>Week 1 Overview: HTML Fundamentals</h1>
  <p><strong>Days 1-5</strong></p>

  <h2>Day 1: Tech Prep, Setup, and Introduction to HTML</h2>
  <ul>
    <li><strong>Task:</strong> install all necessary tools for web development (e.g., code editor, browser).</li>

    <li><strong>Lesson:</strong> What is HTML?</li>
    <li><strong>Exercise:</strong> Create your first HTML file and display "Hello, World!" in the browser.</li>
  </ul>

  <h2>Day 2: HTML Structure and Elements</h2>
  <ul>
    <li><strong>Overview:</strong> Understanding the basic structure of an HTML document.</li>
    <li><strong>Interactive Lesson:</strong> Explore essential HTML elements (e.g., headings, paragraphs, lists).</li>
    <li><strong>Recap:</strong> Review of HTML syntax and structure.</li>
    <li><strong>Exercise:</strong> Create a simple web page using basic HTML elements.</li>
  </ul>

  <h2>Day 3: Working with Links and Images</h2>
  <ul>
    <li><strong>Introduction:</strong> Adding hyperlinks to your web pages.</li>
    <li><strong>Lesson:</strong> How to embed images in HTML.</li>
    <li><strong>Exercise:</strong> Create a web page with links and images.</li>
    <li><strong>Code Practice:</strong> Enhance your page with multiple links and images.</li>
  </ul>

  <h2>Day 4: Organizing Content with Tables and Forms</h2>
  <ul>
    <li><strong>Overview:</strong> Introduction to tables in HTML.</li>
    <li><strong>Lesson:</strong> Creating and styling tables.</li>
    <li><strong>Interactive Lesson:</strong> Introduction to HTML forms.</li>
    <li><strong>Exercise:</strong> Build a web page that includes a table and a basic form.</li>
  </ul>

  <h2>Day 5: Review and Practice</h2>
  <ul>
    <li><strong>Code Challenge:</strong> Create a multi-page website incorporating all elements learned during the week.</li>
    <li><strong>Challenge Question:</strong> Apply HTML to structure content effectively.</li>
    <li><strong>Week 1 Challenge Exercise:</strong> Design a simple personal portfolio website.</li>
  </ul>

  <h2>End of Week</h2>
  <ul>
    <li><strong>Progress Check:</strong> Review your progress and complete any unfinished lessons or exercises.</li>
    <li><strong>Week 1 Quiz:</strong> Self-assessment quiz to test your understanding of HTML basics.</li>
    <li><strong>Workshop:</strong> Attend the Week 1 Workshop and complete the Workshop Assignment.</li>
    <li><strong>Peer Review:</strong> Review and provide feedback on your classmates' projects in the Post-Workshop Forum.</li>
    <li><strong>Feedback Submission:</strong> Share your thoughts on Week 1 and any suggestions for improvement.</li>
  </ul>

  <p><strong>Happy Learning!</strong></p>
</div>


</div>

    </div>
  )
};

export default WelcomWF;

