import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the correct path to your firebase.js file
import "./dashboard.css";

const DashboardPage = () => {
  const [newStudent, setNewStudent] = useState({
    username: '',
    password: '',
    class: '',
    email: '',
    level: ''
  });

  const [students, setStudents] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [editStudent, setEditStudent] = useState(null);
  const [editWorkshop, setEditWorkshop] = useState(null);
  const [editSchedule, setEditSchedule] = useState(null);
  const [activeTab, setActiveTab] = useState('workshops');

  useEffect(() => {
    // Fetch existing students
    const fetchStudents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'formbootcamp/StudentAuth/authentication'));
        const studentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setStudents(studentsData);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    // Fetch existing workshops
    const fetchWorkshops = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'workshopRegistrations'));
        const workshopsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setWorkshops(workshopsData);
      } catch (error) {
        console.error('Error fetching workshop data:', error);
      }
    };

    // Fetch existing schedules
    const fetchSchedules = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'formbootcamp/SCHEDULECALL/001'));
        const schedulesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSchedules(schedulesData);
      } catch (error) {
        console.error('Error fetching schedule data:', error);
      }
    };

    fetchStudents();
    fetchWorkshops();
    fetchSchedules();
  }, []);

  const handleChange = (e) => {
    setNewStudent({ ...newStudent, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editStudent) {
        // Update existing student
        const studentRef = doc(db, 'formbootcamp/StudentAuth/authentication', editStudent.id);
        await updateDoc(studentRef, {
          username: newStudent.username,
          password: newStudent.password,
          class: newStudent.class,
          email: newStudent.email,
          level: newStudent.level
        });
        alert('Student updated successfully');
        setEditStudent(null);
      } else {
        // Add new student
        await addDoc(collection(db, 'formbootcamp/StudentAuth/authentication'), {
          username: newStudent.username,
          password: newStudent.password,
          class: newStudent.class,
          email: newStudent.email,
          level: newStudent.level
        });
        alert('Student created successfully');
      }

      // Clear form and refresh student data
      setNewStudent({
        username: '',
        password: '',
        class: '',
        email: '',
        level: ''
      });
      const querySnapshot = await getDocs(collection(db, 'formbootcamp/StudentAuth/authentication'));
      const studentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStudents(studentsData);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error processing request. Please try again.');
    }
  };

  const handleEdit = (student) => {
    setEditStudent(student);
    setNewStudent({
      username: student.username,
      password: student.password,
      class: student.class,
      email: student.email,
      level: student.level
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'formbootcamp/StudentAuth/authentication', id));
      alert('Student removed successfully');
      // Refresh student data
      const querySnapshot = await getDocs(collection(db, 'formbootcamp/StudentAuth/authentication'));
      const studentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStudents(studentsData);
    } catch (error) {
      console.error('Error removing document:', error);
      alert('Error removing student. Please try again.');
    }
  };

  const handleWorkshopEdit = (workshop) => {
    setEditWorkshop(workshop);
  };

  const handleWorkshopUpdate = async (e) => {
    e.preventDefault();
    try {
      const workshopRef = doc(db, 'workshopRegistrations', editWorkshop.id);
      await updateDoc(workshopRef, {
        email: editWorkshop.email,
        fullName: editWorkshop.fullName,
        phoneNumber: editWorkshop.phoneNumber,
        program: editWorkshop.program,
        state: editWorkshop.state
      });
      alert('Workshop registration updated successfully');
      setEditWorkshop(null);

      // Refresh workshop data
      const querySnapshot = await getDocs(collection(db, 'workshopRegistrations'));
      const workshopsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setWorkshops(workshopsData);
    } catch (error) {
      console.error('Error updating workshop registration:', error);
      alert('Error updating workshop registration. Please try again.');
    }
  };

  const handleWorkshopDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'workshopRegistrations', id));
      alert('Workshop registration removed successfully');
      // Refresh workshop data
      const querySnapshot = await getDocs(collection(db, 'workshopRegistrations'));
      const workshopsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setWorkshops(workshopsData);
    } catch (error) {
      console.error('Error removing document:', error);
      alert('Error removing workshop registration. Please try again.');
    }
  };

  const handleScheduleEdit = (schedule) => {
    setEditSchedule(schedule);
  };

  const handleScheduleUpdate = async (e) => {
    e.preventDefault();
    try {
      const scheduleRef = doc(db, 'formbootcamp/SCHEDULECALL/001', editSchedule.id);
      await updateDoc(scheduleRef, {
        email: editSchedule.email,
        message: editSchedule.message,
        name: editSchedule.name,
        phone: editSchedule.phone
      });
      alert('Schedule updated successfully');
      setEditSchedule(null);

      // Refresh schedule data
      const querySnapshot = await getDocs(collection(db, 'formbootcamp/SCHEDULECALL/001'));
      const schedulesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSchedules(schedulesData);
    } catch (error) {
      console.error('Error updating schedule:', error);
      alert('Error updating schedule. Please try again.');
    }
  };

  const handleScheduleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'formbootcamp/SCHEDULECALL/001', id));
      alert('Schedule removed successfully');
      // Refresh schedule data
      const querySnapshot = await getDocs(collection(db, 'formbootcamp/SCHEDULECALL/001'));
      const schedulesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSchedules(schedulesData);
    } catch (error) {
      console.error('Error removing document:', error);
      alert('Error removing schedule. Please try again.');
    }
  };

  return (
    <div className="dashboard-page">
      <h1>Admin Dashboard</h1>
      <div className="tabs">
        <button onClick={() => setActiveTab('workshops')}>Workshop Registrations</button>
        <button onClick={() => setActiveTab('students')}>Existing Students</button>
        <button onClick={() => setActiveTab('schedules')}>Schedules</button>
      </div>

      {activeTab === 'workshops' && (
        <div className="workshops-list">
          <h2>Workshop Registrations</h2>
          <ul>
            {workshops.map(workshop => (
              <li class ="dashLi" key={workshop.id}>
                <strong>Email:</strong> {workshop.email} |
                <strong> Full Name:</strong> {workshop.fullName} |
                <strong> Phone Number:</strong> {workshop.phoneNumber} |
                <strong> Program:</strong> {workshop.program} |
                <strong> State:</strong> {workshop.state}
                <button onClick={() => handleWorkshopEdit(workshop)}>Edit</button>
                <button onClick={() => handleWorkshopDelete(workshop.id)}>Remove</button>
              </li>
            ))}
          </ul>

          {/* Form to edit workshop registration */}
          {editWorkshop && (
            <div className="workshop-form">
              <h2>Edit Workshop Registration</h2>
              <form onSubmit={handleWorkshopUpdate}>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={editWorkshop.email}
                    onChange={(e) => setEditWorkshop({ ...editWorkshop, email: e.target.value })}
                    required
                  />
                </label>
                <label>
                  Full Name:
                  <input
                    type="text"
                    name="fullName"
                    value={editWorkshop.fullName}
                    onChange={(e) => setEditWorkshop({ ...editWorkshop, fullName: e.target.value })}
                    required
                  />
                </label>
                <label>
                  Phone Number:
                  <input
                    type="text"
                    name="phoneNumber"
                    value={editWorkshop.phoneNumber}
                    onChange={(e) => setEditWorkshop({ ...editWorkshop, phoneNumber: e.target.value })}
                    required
                  />
                </label>
                <label>
                  Program:
                  <input
                    type="text"
                    name="program"
                    value={editWorkshop.program}
                    onChange={(e) => setEditWorkshop({ ...editWorkshop, program: e.target.value })}
                    required
                  />
                </label>
                <label>
                  State:
                  <input
                    type="text"
                    name="state"
                    value={editWorkshop.state}
                    onChange={(e) => setEditWorkshop({ ...editWorkshop, state: e.target.value })}
                    required
                  />
                </label>
                <button type="submit">Update Registration</button>
                <button type="button" onClick={() => setEditWorkshop(null)}>Cancel Edit</button>
              </form>
            </div>
          )}
        </div>
      )}

      {activeTab === 'students' && (
        <div className="students-section">
          <h2>Existing Students</h2>
          <ul>
            {students.map(student => (
              <li class ="dashLi"  key={student.id}>
                <strong>Username:</strong> {student.username} |
                
                <strong> Class:</strong> {student.class} |
                <strong> Email:</strong> {student.email} |
                <strong> Password:</strong> {student.password} |
                <strong> Level:</strong> {student.level}


                <button onClick={() => handleEdit(student)}>Edit</button>
                <button onClick={() => handleDelete(student.id)}>Remove</button>
              </li>
            ))}
          </ul>

          <h2>{editStudent ? 'Edit Student' : 'Add New Student'}</h2>
          <form onSubmit={handleSubmit}>
            <label className='studentLabe' >
              Username:
              <input type="text" name="username" value={newStudent.username} onChange={handleChange} required />
            </label>
            <label className='studentLabe'>
              Password:
              <input type="password" name="password" value={newStudent.password} onChange={handleChange} required />
            </label>
            <label className='studentLabe'>
              Class:
              <input type="text" name="class" value={newStudent.class} onChange={handleChange} required />
            </label>
            <label className='studentLabe'>
              Email:
              <input type="email" name="email" value={newStudent.email} onChange={handleChange} required />
            </label>
            <label className='studentLabe'>
              Level:
              <input type="text" name="level" value={newStudent.level} onChange={handleChange} required />
            </label>
            <button type="submit">{editStudent ? 'Update Student' : 'Add Student'}</button>
            {editStudent && <button type="button" onClick={() => setEditStudent(null)}>Cancel Edit</button>}
          </form>
        </div>
      )}

      {activeTab === 'schedules' && (
        <div className="schedules-section">
          <h2>Schedules</h2>
          <ul>
            {schedules.map(schedule => (
              <li class ="dashLi" key={schedule.id}>
                <strong>Email:</strong> {schedule.email} |
                <strong> Message:</strong> {schedule.message} |
                <strong> Name:</strong> {schedule.name} |
                <strong> Phone:</strong> {schedule.phone}
                <button onClick={() => handleScheduleEdit(schedule)}>Edit</button>
                <button onClick={() => handleScheduleDelete(schedule.id)}>Remove</button>
              </li>
            ))}
          </ul>

          {/* Form to edit schedule */}
          {editSchedule && (
            <div className="schedule-form">
              <h2>Edit Schedule</h2>
              <form onSubmit={handleScheduleUpdate}>
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={editSchedule.email}
                    onChange={(e) => setEditSchedule({ ...editSchedule, email: e.target.value })}
                    required
                  />
                </label>
                <label>
                  Message:
                  <input
                    type="text"
                    name="message"
                    value={editSchedule.message}
                    onChange={(e) => setEditSchedule({ ...editSchedule, message: e.target.value })}
                    required
                  />
                </label>
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={editSchedule.name}
                    onChange={(e) => setEditSchedule({ ...editSchedule, name: e.target.value })}
                    required
                  />
                </label>
                <label>
                  Phone:
                  <input
                    type="text"
                    name="phone"
                    value={editSchedule.phone}
                    onChange={(e) => setEditSchedule({ ...editSchedule, phone: e.target.value })}
                    required
                  />
                </label>
                <button type="submit">Update Schedule</button>
                <button type="button" onClick={() => setEditSchedule(null)}>Cancel Edit</button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardPage;

/*// src/components/DashboardPage.js
import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the correct path to your firebase.js file
import "./dashboard.css"

const DashboardPage = () => {
  const [newStudent, setNewStudent] = useState({
    username: '',
    password: '',
    class: '',
    email: '',
    level: ''
  });

  const [students, setStudents] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [editStudent, setEditStudent] = useState(null);

  useEffect(() => {
    // Fetch existing students
    const fetchStudents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'formbootcamp/StudentAuth/authentication'));
        const studentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setStudents(studentsData);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    // Fetch existing workshops
    const fetchWorkshops = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'workshopRegistrations'));
        const workshopsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setWorkshops(workshopsData);
      } catch (error) {
        console.error('Error fetching workshop data:', error);
      }
    };

    fetchStudents();
    fetchWorkshops();
  }, []);

  const handleChange = (e) => {
    setNewStudent({ ...newStudent, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editStudent) {
        // Update existing student
        const studentRef = doc(db, 'formbootcamp/StudentAuth/authentication', editStudent.id);
        await updateDoc(studentRef, {
          username: newStudent.username,
          password: newStudent.password,
          class: newStudent.class,
          email: newStudent.email,
          level: newStudent.level
        });
        alert('Student updated successfully');
        setEditStudent(null);
      } else {
        // Add new student
        await addDoc(collection(db, 'formbootcamp/StudentAuth/authentication'), {
          username: newStudent.username,
          password: newStudent.password,
          class: newStudent.class,
          email: newStudent.email,
          level: newStudent.level
        });
        alert('Student created successfully');
      }

      // Clear form and refresh student data
      setNewStudent({
        username: '',
        password: '',
        class: '',
        email: '',
        level: ''
      });
      const querySnapshot = await getDocs(collection(db, 'formbootcamp/StudentAuth/authentication'));
      const studentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStudents(studentsData);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error processing request. Please try again.');
    }
  };

  const handleEdit = (student) => {
    setEditStudent(student);
    setNewStudent({
      username: student.username,
      password: student.password,
      class: student.class,
      email: student.email,
      level: student.level
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'formbootcamp/StudentAuth/authentication', id));
      alert('Student removed successfully');
      // Refresh student data
      const querySnapshot = await getDocs(collection(db, 'formbootcamp/StudentAuth/authentication'));
      const studentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStudents(studentsData);
    } catch (error) {
      console.error('Error removing document:', error);
      alert('Error removing student. Please try again.');
    }
  };

  return (
    <div className="dashboard-page">
      <h1>Admin Dashboard</h1>

      {/* Form to create or edit student authentication }
      <div className="student-form">
        <h2>{editStudent ? 'Edit Student Authentication' : 'Create Student Authentication'}</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={newStudent.username}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              name="password"
              value={newStudent.password}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Class:
            <input
              type="text"
              name="class"
              value={newStudent.class}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={newStudent.email}
              onChange={handleChange}
            />
          </label>
          <label>
            Level:
            <input
              type="text"
              name="level"
              value={newStudent.level}
              onChange={handleChange}
            />
          </label>
          <button type="submit">{editStudent ? 'Update Student' : 'Create Student'}</button>
          {editStudent && <button type="button" onClick={() => setEditStudent(null)}>Cancel Edit</button>}
        </form>
      </div>

      {/* Display existing student authentication records }
      <div className="students-list">
        <h2>Existing Students</h2>
        <ul>
          {students.map(student => (
            <li key={student.id}>
              <strong>Username:</strong> {student.username} | 
              <strong> Password:</strong> {student.password} | 
              <strong> Class:</strong> {student.class} |
              <strong> Email:</strong> {student.email} | 
              <strong> Level:</strong> {student.level}
              <button onClick={() => handleEdit(student)}>Edit</button>
              <button onClick={() => handleDelete(student.id)}>Remove</button>
            </li>
          ))}
        </ul>
      </div>

      {/* Display all workshop registrations }
      <div className="workshops-list">
        <h2>Workshop Registrations</h2>
        <ul>
          {workshops.map(workshop => (
            <li key={workshop.id}>
              <strong>Email:</strong> {workshop.email} | 
              <strong>Full Name:</strong> {workshop.fullName} |
              <strong>Phone Number:</strong> {workshop.phoneNumber} |
              <strong>Program:</strong> {workshop.program} |
              <strong>State:</strong> {workshop.state}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardPage;
*/