// src/class/login.js

import React from 'react';

const W1f1 = () => {
  
  

  return (
    <div >
      
      <div class="TitleC">
          
          <span className="whatYouLearn>">
              <p>Welcome to Week day 21 WEB FUNDAMENTAL course! This week, you will focus on learning the fundamentals of JavaScript, the last of the three core technologies of the Web</p>
      
          </span>
<h1>Week 1 Overview</h1>

      </div>
      <div class="Video">
          <div class="videoContent">
          <iframe className='iframe00' src="https://www.youtube.com/embed/8hovjjvDK0A?si=_iK-PqWogBvhnfTA" title="YouTube video player"
    frameborder="0" allow="accelerometer; autoplay; 
  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </div>
     <a href="day1quiz1.html"> open quiz1</a> 
     <span >Quiz</span>
      </div>




    </div>
  );
};

export default W1f1;
