import './Welcome.css';
import image1 from '../images/welc1.jpg';  // Import your images here
import image2 from '../images/welc2.jpg';
import image3 from '../images/welc3.jpg';
import image5 from '../images/welc4.jpg';
import image6 from '../images/welc5.jpg';
import React, { useState, useEffect } from 'react';
//import image from'/image.mp4';

const images = [image1, image2, image3, image5, image6]; // Use the imported images here

const Welcome = () => {
  const OpenVideoYouTube = () => {
    window.open("https://www.youtube.com/watch?v=ORq_mtNZ5xY");
  }

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 15000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='welcomMain'>
      <video className="videoBack" autoPlay loop muted>
        <source src="/image.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <section className="welcom">
        <div className="welcom-content">
          <span className="welcom-headline">
            Join <span className='accentColor'>FlexAcademic</span> Bootcamp
            <br /><span className='para'>Start your tech career with the most affordable coding
              Boot Camp program.
              <br /></span>
          </span>
          <p className="welcom-subheadline">
            A Programming Course guided by instructors, mentors, and supported by a vibrant community of fellow students.
            Are you ready to dive into the world of data analysis and enhance your career prospects?
            <br /> Participate in live workshops every week.
          </p>
          <a href="#programs" className="cta-button">Get Started</a>
          <button className="watchV" onClick={OpenVideoYouTube}>Watch Video</button>
        </div>
        <div className="welcom-image">
          <img className='welcoming' src={images[currentImageIndex]} alt="Welcome" />
        </div>
      </section>
    </div>
  );
};

export default Welcome;
