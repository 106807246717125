import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Programs.css';
import image4 from '../images/sven.jpg';
import image2 from '../images/atee.jpeg';
import image from '../images/papy.png';
import image5 from '../images/kim.jpg';

const Programs = () => {
  const moveToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const clicMoreEl = document.querySelectorAll(".clickMore");
    clicMoreEl.forEach(button => {
      button.addEventListener("click", changeMoreDisplay);
    });

    function changeMoreDisplay(e) {
      const moreEl = e.target.nextElementSibling;
      if (moreEl.style.display === "none" || moreEl.style.display === "") {
        moreEl.style.display = "block";
      } else {
        moreEl.style.display = "none";
      }
    }

    return () => {
      clicMoreEl.forEach(button => {
        button.removeEventListener("click", changeMoreDisplay);
      });
    };
  }, []);
  return (
    <section className="programs">

      <div className="progContainer">
        <span className="programs-headline">Our Programs</span>
        <span className='progText'>
          Explore our  courses for beginners, intermediates,
          and advanced learners to help you achieve your
          career goals.        </span>

        <button className='btncourse'>Download Our Master Course Packet</button>
        <div className="allprograms">
          <div className="progRight">


            <div className="program">
              <div className='progTitle'><h3>Web Development Fundamentals Bootcamp<br></br>5 Weeks | 2 Portfolio Projects |<br></br> Total: 88 Hours : $350  </h3>
              
              </div>
              <div className='progDetail'>
                What You'll Learn:



                <h3>HTML Fundamentals: </h3>Master the basics of web page structure, elements, attributes, and document hierarchy.
                CSS Essentials: Gain expertise in styling web pages with properties, selectors, and units to
                create visually stunning designs.
                <button className="clickMore">more...</button>
                <span className="more">
                  JavaScript Basics: Learn how to add interactivity and dynamic features
                  to your web pages with foundational JavaScript skills.
                  Why Choose Us?

                  Commitment: Immerse yourself in an intensive 5-week program with support from friends and family.
                  <h3>Hands-On Workshops: </h3>Get practical experience by bringing your own laptop to engage in interactive coding sessions and workshops.
                  No Experience Needed: Perfect for beginners—no prior knowledge of HTML, CSS, or Bootstrap required.
                  Build Your Portfolio: Craft your first website and complete two impressive web projects that will make your resume stand out.
                  <h3>Real-World Projects:</h3> Tackle industry-relevant challenges with projects that simulate real-world scenarios, including building a personal website and developing a dynamic web application.
                </span>   </div>
            </div>
            <div className="program0">
              <div className='progTitle'> <h3>Web Development & JavaScript Course 
                <br></br>14 Weeks + 3 Project Portfolio | <br></br>Total 340 Hours : 550$

                </h3>

        </div>
              <div className='progDetail'>

                What You'll Learn:

                <h3>Master JavaScript: </h3>Deep dive into JavaScript, exploring data structures, algorithms, and best practices.
                Browser DOM Manipulation: Learn to control the browser DOM and handle asynchronous API calls.
                <button className="clickMore">more...</button>
                <span className="more"><h3>Build Web Pages:</h3> Create dynamic and responsive web pages using HTML, CSS, and JavaScript.
                  Course Highlights:

                  <h3>Online Lectures & Exercises: </h3>Engage in interactive learning with hands-on practice.
                  Weekly Graded Workshops: Complete assignments that contribute to your final portfolio.
                  <h3>Portfolio Development:</h3> Build projects to showcase in your resume and demonstrate your skills to potential employers.
                  Expert Instruction: Learn from industry professionals with real-world experience, ensuring you gain practical knowledge and insights into the latest web development trends.
                  Capstone Project: Complete a comprehensive capstone project that integrates everything you've learned, resulting in a fully functional website or web application that showcases your new skills.

                </span>    </div>
            </div>

            <div className="program1">

              <div className='progTitle'> <h3>Full-Stack Web & Mobile Developer <br></br> Program
               22 Weeks | 16 Projects<br></br> Total 436 Hours : 750$</h3>
              </div>
              <div className='progDetail'>
                What You'll Learn:

                <h3>Full-Stack Development: </h3>Launch your career as a Full Stack Web and Mobile Developer by mastering essential skills.<p></p>
                React Mastery: Build Single Page Applications with React, creating <button className="clickMore">more...</button>
                <span className="more">dynamic and user-friendly interfaces.
                  Mobile Development: Develop mobile applications for Android and iPhone using React Native.<p></p>
                  <h3>Database Management: </h3> Handle databases with Node.js, Google Cloud, and Firebase. Learn MongoDB for efficient data storage and management.
                  Complex Application Development: Work on complex projects that challenge and expand your skills.
                  Collaboration: Collaborate on projects using GitHub, enhancing your ability to work in a team setting.<p></p>
                  Program Highlights:

                  <h4>16 Projects: </h4>Work on 16 real-world projects to solidify your understanding and application of cutting-edge technologies.
                  Comprehensive Learning: Gain expertise in both web and mobile development, making you a versatile developer ready for the job market.
                </span>
              </div>
            </div>


            <div className="program2">
              <div className='progTitle'>
                <h3>Back-End, SQL, and DevOps with Python<br></br>master back-end development 
                <br></br> Total 26-week : 1050$</h3>
              </div>
              <div className='progDetail'>
                What You'll Learn:

                <h3>Back-End Development:</h3> Gain expertise in Python for building websites and data science projects, using frameworks like Flask and Django.
                SQL Databases: Master PostgreSQL to manage and scale complex data <button className="clickMore">more...</button>
                <span className="more">workloads efficiently.<p></p>
                DevOps Practices: Develop, ship, and run>applications with Docker, and implement Continuous Integration and Continuous Delivery (CI/CD).
                  Cloud Computing: Utilize cloud services like Amazon Web Services (AWS), Microsoft's Azure, and Google Cloud/Firestore for on-demand computing and storage solutions.
                  Software Development Models: Learn and apply Agile, Waterfall, and Spiral development models.<p></p>
                  Program Highlights:

                  <h3>Comprehensive Back-End Training:</h3> Build the foundation for a successful career in back-end development.
                  DevOps Mastery: Learn to automate and optimize the software development process.
                  Cloud Integration: Leverage leading cloud platforms to deploy and scale applications.
                  Real-World Skills: Prepare for the tech industry with hands-on experience and practical knowledge.
                </span>  </div>

            </div>
            <div className="program12">
              <div className='progTitle'>
                <h3>Data Analyst with Power BI and Tableau<br></br> 8 Weeks | 4 project Prorotfolio <br></br>| Total 88 Hours :350$</h3>
             </div>
              <div className='progDetail'>
                Roles You Can Pursue:

                <h3>Data Analyst: </h3>Analyze and interpret complex data sets to help organizations make informed decisions.
                Business Intelligence Analyst: Develop reports and dashboards <button className="clickMore">more...</button>
                <span className="more"> to visualize business performance and trends.
                  Data Visualization Specialist: Createcompelling visualizations and dashboards to present data insights effectively.
                  Reporting Analyst: Generate detailed reports and analyze data to support business operations and strategy.
                  Course Highlights:

                  <h3>Introduction to Data Analysis: </h3>Understand key concepts and the essential role of data analysts.
                  <h3>With Power BI: </h3>Learn to connect to data sources, create interactive reports and dashboards, and publish your work.
                  <h3>With Tableau: </h3>Gain expertise in connecting to
                  data, building visualizations, and sharing your insights.
                  <h3>Case Studies:</h3> Apply your knowledge through real-world projects and case studies, bridging theory with practical experience.
                  Job Opportunities After Completion:

                  A significant number of job postings for data analysts, business analysts, and similar roles list proficiency in Power BI or Tableau as a required or preferred skill. Enhance your career prospects with expertise in these powerful tools!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>




          <span className='progText'>
            <div className="programs-headline">Gain exceptional knowledge from most
              motivated and experienced
              instructor! </div>
            <span className="progDetails">
              With a proven track record and market appreciation, our instructor will guide you every
              step of the way.<br></br>
              It's time for you to enjoy your work. Our mentoring
              and personalized support will help you learn quickly and overcome any
              coding challenges you face.<p></p>Enjoy hands-on mentorship and expert
              insights, ensuring you acquire
              practical
              skills and real-world experience.
            </span>
          </span>
        </div>

        <div className='progLeft'>


          <div className='inst'>
            <img className="imLeft" src={image} alt="images" />
            <div className='instName'>Engineer Papy M.</div>
            <div className='instrTitle'> Co-Founder Code4All <br></br> Bs Information Technology<br></br> University of Phoenix Arizona</div>
          </div>

          <div className='inst'>
            <img className="imLeft" src={image5} alt="images" />
            <div className='instName'>Engineer Storm R </div>
            <div className='instrTitle'>Expert Full Stack developer<br></br>Bs Information Technology<br></br> State University of New York</div>
          </div>
          <div className='inst'>
            <img className="imLeft" src={image4} alt="images" />
            <div className='instName'>Engineer Sven Esser</div>
            <div className='instrTitle'> 10 years Experiences <br></br>
              CEO Sven <br></br>Limburg, Netherlands</div>
          </div>
          <div className='inst'>
            <img className="imLeft" src={image2} alt="images" />
            <div className='instName'>Engineer ATEESH K.</div>
            <div className='instrTitle'>Full Stack Developer<br></br> Bs in Computer Science <br></br>SealStone UK</div>
          </div>



        </div>




      </div>


    </section>
  );
};

export default Programs;