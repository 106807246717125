import React from 'react';
import "./About.css"


const About = () => {
  return (
    <section className="about" id="about">
  <span className= "why">Why Choose Us?</span> <p></p>      <div className="containerAbout">
      
        <p className="about-subheadline">
          <span className='seconds'>At FlexAcademic, we believe that financial 
            constraints<br></br> should never exclude anyone from education. 
        </span>
        
        <br></br> <span className='why3'>Our Full Stack program costs under $3,499, with flexible payment 
      
        <br></br><span className='seconds'>With FlexAcademics lets you learn 
          at your own pace and gain technical 
          skills <br></br>without the risk of costly loans.</span>
       
         <br>
        </br> </span></p><span className='why'>  Join now and Claim your FREE First Week</span>
        
     
        <div className="features">
          <div className="feature">
            
            <h3 className="feature-title">Expert Instructors</h3>
            <p className="feature-description">Gain knowledge from experienced experts who bring practical insights to your learning journey..</p>
          </div>
          <div className="feature">
           
            <h3 className="feature-title">Hands-On Projects</h3>
            <p className="feature-description">Engage in immersive projects that simulate industry challenges and solutions. Build a robust portfolio with hands-on projects designed to mirror professional scenarios.</p>
          </div>
          <div className="feature">
           
            <h3 className="feature-title">Career Support</h3>
            <p className="feature-description">Benefit from personalized career services, including resume building, interview preparation, and job placement assistance.</p>
          </div>
          <div className="feature">
       
            <h3 className="feature-title">Flexible Learning</h3>
            <p className="feature-description">Full-time, part-time, and self-paced.
            Study at your own pace with a schedule that fits your lifestyle and commitments.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;