import React, { useState , useEffect} from 'react';
import './learningPage.css'

import { useNavigate } from 'react-router-dom';
// Import the content for each day
import Day1Content from './JAVA/WeekOneDayOneJava.js';
import Day2Content from  './JAVA/WeekOneDayTwoJava.js';
import Day3Content from './JAVA/TestJava.js';
import Day4Content from './HTML/w1d1.js';
import Day5Content from './HTML/w1d1.js';
import WelcomWF from './WelcomWF.js';


function LearningPage() {
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedContent, setSelectedContent] = useState(<WelcomWF/>);
  const [userlevel, setUserlevel] = useState('');
  const [username, setUsername] = useState('');
  const studentAccess = parseInt(localStorage.getItem('level')) || 0; 
  const navigate = useNavigate();

  useEffect(() => {
  const storedUsername = localStorage.getItem('username');
  const storedLevel = localStorage.getItem('level');
  setUsername(storedUsername || 'User');
  setUserlevel(storedLevel || 'level');
});
  const toggleWeek = (week) => {
    setSelectedWeek(selectedWeek === week ? null : week);
  };
  const handleDayClick = (content) => {
    setSelectedContent(content);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('level');
    navigate('/login');
  };



  return (
    <div className='LearningPage'>
      <div className='LHeader'>
      <div className="navWelcom">
          <h1>FlexAcademic -JAVA-   </h1><br></br>
          <a href="#" class="logout" onClick={handleLogout}>Logout</a>
          <span>Welcome <span>{username}</span> ,</span>
          <span className='lesson'>{userlevel} weeks lessons available </span>

        </div>
      </div>


      <div className='LearnMain'>

        {/* SIDEBARE*/}
     
        <div className='LleftBar'>
     
          <div className="sideNav">
           
            <h2>Course Informations</h2>
            <div className="welcomeMenu"></div>

            <span id="courseInfo">JAVA
              <br /><p></p>
              <button className='overview'onClick={() => handleDayClick(<WelcomWF />)}>Course overview</button>
              <button className='overview2'>Live Workshop</button>
            </span>
            <p></p>

            {/* Week 1 */}
            <span className="mainWeek week1" onClick={() => toggleWeek('week1')}>
              Week 1: Overview, JAVA
            </span>
            {selectedWeek === 'week1' && (
              <ul className="weekClass">
                 <li onClick={() => handleDayClick(<Day1Content />)}>DAY 1 : JAVA1L</li>
                <li onClick={() => handleDayClick(<Day2Content />)}>DAY 2 : Object-Oriented Programming Concepts</li>
                <li onClick={() => handleDayClick(<Day3Content />)}>DAY 3 : Working with Links and Images</li>
                <li onClick={() => handleDayClick(<Day4Content />)}>DAY 4 : Organizing Content with Tables and Forms</li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 5 : Review and Practice </li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 6 : 3hours Live Work Shop (Zoom) </li>
              </ul>
            )}

            {/* Week 2 */}
            <span className="mainWeek week2" onClick={() => toggleWeek('week2')}>
              Week 2: JavaScript Advanced
            </span>
            {selectedWeek === 'week2' && (
              <ul className="weekClass">
                <li onClick={() => handleDayClick(<Day1Content />)}>DAY 1 : Tech Prep, Setup, and Introduction to HTML</li>
                <li onClick={() => handleDayClick(<Day2Content />)}>DAY 2 : HTML Structure and Elements</li>
                <li onClick={() => handleDayClick(<Day3Content />)}>DAY 3 : Working with Links and Images</li>
                <li onClick={() => handleDayClick(<Day4Content />)}>DAY 4 : Organizing Content with Tables and Forms</li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 5 : Review and Practice </li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 6 : 3hours Live Work Shop (Zoom) </li>  </ul>
            )}

            {/* Week 3 */}
            <span className="mainWeek week3" onClick={() => toggleWeek('week3')}>
              Week 3: JavaScript Advanced
            </span>
            {selectedWeek === 'week3' && (
              <ul className="weekClass">
                <li onClick={() => handleDayClick(<Day1Content />)}>DAY 1 : Tech Prep, Setup, and Introduction to HTML</li>
                <li onClick={() => handleDayClick(<Day2Content />)}>DAY 2 : HTML Structure and Elements</li>
                <li onClick={() => handleDayClick(<Day3Content />)}>DAY 3 : Working with Links and Images</li>
                <li onClick={() => handleDayClick(<Day4Content />)}>DAY 4 : Organizing Content with Tables and Forms</li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 5 : Review and Practice </li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 6 : 3hours Live Work Shop (Zoom) </li> </ul>
            )}

            {/* Week 4 */}
            <span className="mainWeek week4" onClick={() => toggleWeek('week4')}>
              Week 4: React Basics
            </span>
            {selectedWeek === 'week4' && (
              <ul className="weekClass">
                <li onClick={() => handleDayClick(<Day1Content />)}>DAY 1 : Tech Prep, Setup, and Introduction to HTML</li>
                <li onClick={() => handleDayClick(<Day2Content />)}>DAY 2 : HTML Structure and Elements</li>
                <li onClick={() => handleDayClick(<Day3Content />)}>DAY 3 : Working with Links and Images</li>
                <li onClick={() => handleDayClick(<Day4Content />)}>DAY 4 : Organizing Content with Tables and Forms</li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 5 : Review and Practice </li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 6 : 3hours Live Work Shop (Zoom) </li> </ul>
            )}

            {/* Week 5 */}
            <span className="mainWeek week5" onClick={() => toggleWeek('week5')}>
              Week 5: React Advanced
            </span>
            {selectedWeek === 'week5' && (
              <ul className="weekClass">
                <li onClick={() => handleDayClick(<Day1Content />)}>DAY 1 : Tech Prep, Setup, and Introduction to HTML</li>
                <li onClick={() => handleDayClick(<Day2Content />)}>DAY 2 : HTML Structure and Elements</li>
                <li onClick={() => handleDayClick(<Day3Content />)}>DAY 3 : Working with Links and Images</li>
                <li onClick={() => handleDayClick(<Day4Content />)}>DAY 4 : Organizing Content with Tables and Forms</li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 5 : Review and Practice </li>
                <li onClick={() => handleDayClick(<Day5Content />)}>DAY 6 : 3hours Live Work Shop (Zoom) </li>   </ul>
            )}
          </div>
          
        {/* END OF SIDEBARE*/}
        </div>
        <div className='LearningContent'>
      
          {selectedContent}
          </div>
       
      </div>
    </div>
  );
}

export default LearningPage;
