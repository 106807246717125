import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the correct path to your firebase.js file
import './ProgramForm.css';
import calendar from '../images/calendar.jpg';
import StateSelect from './StateSelect'; // Import the StateSelect component

const ProgramForm = () => {
  const [form, setForm] = useState({
    fullName: '',
    program: '',
    email: '',
    phoneNumber: '',
    address: '',
    state: '',
    jobTitle: '',
    attendedBootcamp: 'No',
    aboutYou: '',
    timesub: ''
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const now = new Date();
      const timestamp = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
      const formWithTimestamp = { ...form, timesub: timestamp };

      console.log('Submitting form:', formWithTimestamp);
      await addDoc(collection(db, 'workshopRegistrations'), formWithTimestamp);
      alert('Form submitted successfully');

      // Reset form
      setForm({
        fullName: '',
        program: '',
        email: '',
        phoneNumber: '',
        address: '',
        state: '',
        jobTitle: '',
        attendedBootcamp: 'No',
        aboutYou: '',
        timesub: ''
      });
    } catch (error) {
      console.error('Error adding document:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <div className='mainForm'>
      <div className='formhead'>
        <span className='int'> Add your name to the future software engineer list</span><p></p>
        <span className='titleDet'>
          Register for the bootcamp to check if you qualify for a free first week. <br />      </span>
      </div>

      <div className='form-container'>
        <div className='formDetail'>
          <div className='formTitle'>
            <h2>Registration Form</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <label>
              * Full Name:
              <input type="text" name="fullName" value={form.fullName} onChange={handleChange} required />
            </label>

            <label>
              * Program: If you have no prior experience, we recommend starting with Web Development.
              <select name="program" value={form.program} onChange={handleChange} required>
                <option value="">Choose your program</option>
                <option value="Web Development">Web Development Fundamentals Bootcamp</option>
                <option value="Software Engineering">Front-End Boot Camp Web & Mobile Apps</option>
                <option value="Full Stack Web">Full-Stack Web & Mobile Developer Program</option>
                <option value="Back End">Back-End, SQL, and DevOps with Python</option>
              </select>
            </label>

            <label>
              * Email Address:
              <input type="email" name="email" value={form.email} onChange={handleChange} required />
            </label>

            <label>
              * Phone Number:
              <input type="tel" name="phoneNumber" value={form.phoneNumber} onChange={handleChange} required />
            </label>
            <label>
            Address: <br></br>
            <input type="text" name="address" value={form.address} onChange={handleChange} />
            </label>
         

            <label>
              <StateSelect 
                value={form.state}
                onChange={handleChange}
              />
            </label>

            <label>
              Job Title:
              <input type="text" name="jobTitle" value={form.jobTitle} onChange={handleChange} />
            </label>

            <label>
              Have you attended any other Coding Boot Camp in the past?
              <div>
                <label>
                  <input type="radio" name="attendedBootcamp" value="Yes" onChange={handleChange} />
                  Yes
                </label>
                <label>
                  <input type="radio" name="attendedBootcamp" value="No" onChange={handleChange} defaultChecked />
                  No
                </label>
              </div>
            </label>

            <label>
              Tell us about yourself and your goals:
              <textarea name="aboutYou" value={form.aboutYou} onChange={handleChange} required></textarea>
            </label>

            <button type="submit">Submit</button>
            <span className='bysubmit'>By submitting this form, you agree that Code4All may contact you regarding this bootcamp. You may opt out of receiving communications at any time</span>
          </form>
        </div>
        <div className='formComment'>
          <span className='leftTitle'>Web Development Fundamentals Bootcamp</span><p></p>
          <span className='leftText'>6 Weeks + 2 Projects | 88 Hours</span>
          <div className='infos'>
            <p></p>
            <span className='titleDate'>September 18th <br></br> to <br></br>October 24th 2024</span>
        
            <img className="imcal" src={calendar} alt="calendar" />    <p></p>
            <span className='titleDate'>Fill out the form to reserve your spot.<br></br>First week free! <br></br>Limited spots available, don't miss the chance to start a new
            career and achieve the life you deserve.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramForm;
