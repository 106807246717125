import React from 'react';
import '../../class/learningPage.css';
import { useState } from 'react';



const WeekOneDayTwoJava = () => {



        // Quiz state and functions
        const [answers, setAnswers] = useState({});
        const [result, setResult] = useState('');
    
        const correctAnswers = {
            q1: 'b',
            q2: 'b',
            q3: 'c',
            q4: 'b',
            q5: 'a',
            q6: 'b',
            q7: 'b',
            q8: 'c',
            q9: 'c',
            q10: 'b'
        };
    
        const handleChange = (question, value) => {
            setAnswers({
                ...answers,
                [question]: value
            });
        };
    
        const checkAnswers = () => {
            let score = 0;
            const totalQuestions = Object.keys(correctAnswers).length;
    
            for (const [question, answer] of Object.entries(correctAnswers)) {
                if (answers[question] === answer) {
                    score++;
                }
            }
    
            setResult(
                score === totalQuestions
                    ? `Congratulations! You got all ${score} out of ${totalQuestions} correct!`
                    : `You got ${score} out of ${totalQuestions} correct. Try again!`
            );
        };
    
        // Course and navigation state
        const [quizItem, setQuizItem] = useState('course1'); // Default to show video1 and course1
    
        const handleClassClick = (item) => {
            setQuizItem(quizItem === item ? '' : item); // Toggle between the clicked item and hiding all
        };


  return (
    <div className="coursePage">

<div className="TitleC">
    
 <section>
        <h2>Arrays in Java</h2>
        <p>
          Arrays in Java are declared with the type of elements they will contain. For example, if the array will contain 
          strings, the declaration starts with <code>String[]</code> followed by the name of the array, then an equals sign 
          (<code>=</code>), and finally the array values enclosed in curly braces <code>{}</code>.
        </p>
        <p>
          Example for an array of strings:
          <pre>
            <code>String[] fruits = {"apple", "orange", "banana"};</code>
          </pre>
        </p>
        <p>
          If the array will contain numbers, you use <code>int[]</code> followed by the array name:
          <pre>
            <code>int[] numbers = {1, 2, 3, 4};</code>
          </pre>
        </p>
      </section>

      <section>
        <h2>Looping Through an Array</h2>
        <p>
          There are two common ways to loop through an array in Java: using a <strong>for loop</strong> and a 
          <strong>for-each loop</strong>.
        </p>
        <h3>For Loop</h3>
        <p>
          The <code>for</code> loop uses an index to access each element in the array:
          <pre>
            <code>
              {`for (int i = 0; i < arrayName.length; i++) {
    System.out.println(arrayName[i]);
}`}
            </code>
          </pre>
        </p>
        <h3>For-Each Loop</h3>
        <p>
          The <code>for-each</code> loop simplifies the syntax by iterating over each element in the array:
          <pre>
            <code>
              {`for (int element : arrayName) {
    System.out.println(element);
}`}
            </code>
          </pre>
          Here, <code>element</code> is a variable that represents each item in the <code>arrayName</code> array during 
          each iteration.
        </p>
      </section>

      <section>
        <h2>Complete Example</h2>
        <p>Here is a complete example that demonstrates array declaration and looping:</p>
        <pre>
          <code>
            {`public class Main {

    public static void main(String[] args) {
        // Declare and initialize an array of strings
        String[] fruits = {"apple", "orange", "banana"};

        // Declare and initialize an array of integers
        int[] numbers = {1, 2, 3, 4};

        // Loop through the fruits array using a for loop
        for (int i = 0; i < fruits.length; i++) {
            System.out.println(fruits[i]);
        }

        // Loop through the numbers array using a for-each loop
        for (int number : numbers) {
            System.out.println(number);
        }
    }
}`}
          </code>
        </pre>
<div>
      <h2>Actions in Arrays</h2>
      <p>In Java, you can perform various actions on arrays such as adding, removing, updating, and searching for elements. Since arrays in Java are fixed in size once created, some actions require creating a new array. Alternatively, you can use collections like <code>ArrayList</code> for more dynamic operations.</p>
      
      <h3>1. Adding Elements</h3>
      <p>Arrays in Java are fixed in size. To "add" an element, you need to create a new array with a larger size and copy the elements from the old array into the new one, then add the new element. Here’s an example:</p>
      <pre>
        <code>
{`import java.util.Arrays;

public class Main {
    public static void main(String[] args) {
        int[] originalArray = {1, 2, 3};
        int newElement = 4;

        // Create a new array with one more element
        int[] newArray = Arrays.copyOf(originalArray, originalArray.length + 1);

        // Add the new element
        newArray[newArray.length - 1] = newElement;

        // Print new array
        System.out.println(Arrays.toString(newArray));
    }
}`}
        </code>
      </pre>

      <h3>2. Removing Elements</h3>
      <p>Removing an element involves creating a new array without the element to be removed. Here’s an example:</p>
      <pre>
        <code>
{`import java.util.Arrays;

public class Main {
    public static void main(String[] args) {
        int[] originalArray = {1, 2, 3, 4};
        int indexToRemove = 2; // Index of element '3'

        // Create a new array with one less element
        int[] newArray = new int[originalArray.length - 1];
        
        // Copy elements before the index
        System.arraycopy(originalArray, 0, newArray, 0, indexToRemove);
        
        // Copy elements after the index
        System.arraycopy(originalArray, indexToRemove + 1, newArray, indexToRemove, originalArray.length - indexToRemove - 1);

        // Print new array
        System.out.println(Arrays.toString(newArray));
    }
}`}
        </code>
      </pre>

      <h3>3. Updating Elements</h3>
      <p>You can update an element in an array directly by accessing its index:</p>
      <pre>
        <code>
{`public class Main {
    public static void main(String[] args) {
        int[] array = {1, 2, 3, 4};

        // Update element at index 2
        array[2] = 10;

        // Print updated array
        for (int element : array) {
            System.out.println(element);
        }
    }
}`}
        </code>
      </pre>

      <h3>4. Searching for Elements</h3>
      <p>To search for an element in an array, you can iterate through it:</p>
      <pre>
        <code>
{`public class Main {
    public static void main(String[] args) {
        int[] array = {1, 2, 3, 4};
        int target = 3;
        boolean found = false;

        // Search for the target
        for (int i = 0; i < array.length; i++) {
            if (array[i] == target) {
                found = true;
                System.out.println("Element found at index: " + i);
                break;
            }
        }

        if (!found) {
            System.out.println("Element not found");
        }
    }
}`}
        </code>
      </pre>

      <h3>5. Copying Arrays</h3>
      <p>You can copy arrays using methods like <code>System.arraycopy()</code> or <code>Arrays.copyOf()</code>:</p>
      <pre>
        <code>
{`import java.util.Arrays;

public class Main {
    public static void main(String[] args) {
        int[] originalArray = {1, 2, 3, 4};

        // Copy the entire array
        int[] copiedArray = Arrays.copyOf(originalArray, originalArray.length);

        // Print copied array
        System.out.println(Arrays.toString(copiedArray));
    }
}`}
        </code>
      </pre>

      <p>These actions can be performed more easily with dynamic collections like <code>ArrayList</code>, which handle resizing and other operations more flexibly.</p>
    </div>
      </section>
<div>
            <h1>Java Methods Overview</h1>

            <h2>Structure of Methods in Java</h2>
            <p>In Java, methods are blocks of code designed to perform a specific task. They follow a particular structure:</p>
            <pre>
                <code>
                    <strong>Access Modifier</strong> <strong>Return Type</strong> <strong>Method Name</strong>(<strong>Parameters</strong>) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp; // Method body<br />
                    &nbsp;&nbsp;&nbsp;&nbsp; <strong>return</strong> <strong>Expression</strong>;<br />
                    &#125;
                </code>
            </pre>
            <p>Here is a breakdown:</p>
            <ul>
                <li><strong>Access Modifier</strong>: Defines the visibility of the method (e.g., <code>public</code>, <code>private</code>).</li>
                <li><strong>Return Type</strong>: The type of value the method returns (e.g., <code>int</code>, <code>void</code>).</li>
                <li><strong>Method Name</strong>: The name of the method, which should be descriptive of its function.</li>
                <li><strong>Parameters</strong>: Optional values passed to the method.</li>
                <li><strong>Method Body</strong>: The code that defines what the method does.</li>
            </ul>

            <h2>Examples of Java Methods</h2>

            <h3>1. Static Method</h3>
            <p>Static methods belong to the class itself and can be invoked without creating an instance of the class. Here's an example:</p>
            <pre>
                <code>
                    public class javaClass &#123;<br />
                    &nbsp;&nbsp;public static int FindArea(int L, int W) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;return L * W;<br />
                    &nbsp;&nbsp;&#125;<br />
                    <br />
                    &nbsp;&nbsp;public static void main(String[] args) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;int areaResult = FindArea(5, 6);<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Area: " + areaResult);<br />
                    &nbsp;&nbsp;&#125;<br />
                    &#125;
                </code>
            </pre>

            <h3>2. Non-static Method</h3>
            <p>Non-static methods belong to an instance of the class and require creating an instance to be invoked. Here's an example:</p>
            <pre>
                <code>
                    public class javaClass &#123;<br />
                    &nbsp;&nbsp;public int FindTotal(int a, int b) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;return a + b;<br />
                    &nbsp;&nbsp;&#125;<br />
                    <br />
                    &nbsp;&nbsp;public static void main(String[] args) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;javaClass instance = new javaClass(); // Create an instance<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;int totalResult = instance.FindTotal(3, 5);<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Total: " + totalResult);<br />
                    &nbsp;&nbsp;&#125;<br />
                    &#125;
                </code>
            </pre>

            <h2>Invoking Methods</h2>
            <p>The location of the method declaration affects how it is invoked:</p>
            <ul>
                <li><strong>Static Methods:</strong> Can be invoked directly using the class name or from another static context within the same class.</li>
                <li><strong>Non-static Methods:</strong> Must be invoked through an instance of the class.</li>
            </ul>

            <h3>Example of Invoking Static and Non-static Methods</h3>
            <p>Here is how to invoke both static and non-static methods:</p>
            <pre>
                <code>
                    public class javaClass &#123;<br />
                    &nbsp;&nbsp;public static int FindArea(int L, int W) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;return L * W;<br />
                    &nbsp;&nbsp;&#125;<br />
                    <br />
                    &nbsp;&nbsp;public int FindTotal(int a, int b) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;return a + b;<br />
                    &nbsp;&nbsp;&#125;<br />
                    <br />
                    &nbsp;&nbsp;public static void main(String[] args) &#123;<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;int areaResult = FindArea(5, 6); // Static method invocation<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Area: " + areaResult);<br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;javaClass instance = new javaClass(); // Create an instance<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;int totalResult = instance.FindTotal(3, 5); // Non-static method invocation<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Total: " + totalResult);<br />
                    &nbsp;&nbsp;&#125;<br />
                    &#125;
                </code>
            </pre>

            <h2>Summary</h2>
            <ul>
                <li><strong>Static Methods</strong>: Can be invoked without creating an instance. Example: <code>javaClass.FindArea(5, 6);</code></li>
                <li><strong>Non-static Methods</strong>: Require an instance to be invoked. Example: <code>javaClass instance = new javaClass(); instance.FindTotal(3, 5);</code></li>
                <li><strong>Invoking Methods</strong>: Static methods can be called directly, while non-static methods need an instance of the class.</li>
            </ul>
        </div>
        <div>


      <h2>Object-Oriented Programming Concepts</h2>

      <h3>1. Object</h3>
      <p>
        An object is a basic unit of Object-Oriented Programming that represents a real-world entity. Objects have state (attributes) and behavior (methods).
      </p>
      
      <pre>
        <code>
{`public class JavaCourse {
    class Dog {
        String name;
        String breed;

        void bark() {
            System.out.println("Woof!");
        }
    }

    public static void main(String[] args) {
        JavaCourse course = new JavaCourse();
        Dog myDog = course.new Dog(); // Object creation
        myDog.name = "Buddy";
        myDog.breed = "Golden Retriever";
        myDog.bark(); // Calls the bark method
    }
}`}
        </code>
      </pre>

      <h3>2. Classes</h3>
      <p>
        A class is a blueprint for creating objects. It defines the properties and behaviors that the objects created from the class will have.
      </p>
      <pre>
        <code>
{`public class JavaCourse {
    class Car {
        String model;
        int year;

        void startEngine() {
            System.out.println("Engine started");
        }
    }

    public static void main(String[] args) {
        JavaCourse course = new JavaCourse();
        Car myCar = course.new Car(); // Creating an object from the class
        myCar.model = "Toyota Camry";
        myCar.year = 2021;
        myCar.startEngine(); // Calls the startEngine method
    }
}`}
        </code>
      </pre>

      <h3>3. Inheritance</h3>
      <p>
        Inheritance is a mechanism where a new class (subclass) inherits properties and behaviors from an existing class (superclass).
      </p>
      <pre>
        <code>
{`public class JavaCourse {
    class Animal {
        void eat() {
            System.out.println("This animal eats food.");
        }
    }

    class Dog extends Animal {
        void bark() {
            System.out.println("Woof!");
        }
    }

    public static void main(String[] args) {
        JavaCourse course = new JavaCourse();
        Dog myDog = course.new Dog();
        myDog.eat(); // Inherited from Animal class
        myDog.bark(); // Defined in Dog class
    }
}`}
        </code>
      </pre>

      <h3>4. Polymorphism</h3>
      <p>
        Polymorphism allows objects to be treated as instances of their parent class, enabling one interface to be used for a general class of actions.
      </p>
      <pre>
        <code>
{`public class JavaCourse {
    // Compile-time polymorphism (Method Overloading)
    class Calculator {
        int add(int a, int b) {
            return a + b;
        }

        int add(int a, int b, int c) {
            return a + b + c;
        }
    }

    // Runtime polymorphism (Method Overriding)
    class Animal {
        void sound() {
            System.out.println("Some sound");
        }
    }

    class Dog extends Animal {
        @Override
        void sound() {
            System.out.println("Woof!");
        }
    }

    public static void main(String[] args) {
        JavaCourse course = new JavaCourse();

        // Example of method overloading
        Calculator calc = course.new Calculator();
        System.out.println("Sum (2 parameters): " + calc.add(2, 3));
        System.out.println("Sum (3 parameters): " + calc.add(2, 3, 4));

        // Example of method overriding
        Animal myAnimal = course.new Dog();
        myAnimal.sound(); // Outputs "Woof!" due to method overriding
    }
}`}
        </code>
      </pre>

      <h3>5. Abstraction</h3>
      <p>
        Abstraction hides the complex implementation details and shows only the essential features of an object.
      </p>
      <pre>
        <code>
{`public class JavaCourse {
    abstract class Animal {
        abstract void sound(); // Abstract method (no implementation)
    }

    class Dog extends Animal {
        @Override
        void sound() {
            System.out.println("Woof!");
        }
    }

    public static void main(String[] args) {
        JavaCourse course = new JavaCourse();
        Animal myDog = course.new Dog();
        myDog.sound(); // Outputs "Woof!"
    }
}`}
        </code>
      </pre>

      <h3>6. Encapsulation</h3>
      <p>
        Encapsulation involves wrapping data (variables) and code (methods) into a single unit (class), restricting direct access to some components.
      </p>
      <pre>
        <code>
{`public class JavaCourse {
    class Person {
        private String name; // Private data member

        // Getter method
        public String getName() {
            return name;
        }

        // Setter method
        public void setName(String name) {
            this.name = name;
        }
    }

    public static void main(String[] args) {
        JavaCourse course = new JavaCourse();
        Person person = course.new Person();
        person.setName("John"); // Set the value using setter
        System.out.println(person.getName()); // Get the value using getter
    }
}`}
        </code>
      </pre>
      <div>
      <h1>Object-Oriented Programming (OOP) Example in Java</h1>

      <h2>Code Example:</h2>
      <pre>
        <code>
{`import java.util.concurrent.Callable;

public class ClassCourse {
    // Inner class Student
    class Student {
        String name;
        String address;
        int age;

        // Method to enroll the student
        void enroll() {
            System.out.println("Student fully enrolled");
        }

        // Method to check if the student passes
        boolean pass(int grade, int score) {
            if (grade > score) {
                System.out.println("Student passes");
                return true;
            } else {
                System.out.println("Student fails");
                return false;
            }
        }
    }

    public static void main(String[] args) {
        // Create an instance of the outer class
        ClassCourse course = new ClassCourse();

        // Create an instance of the inner class Student
        Student student = course.new Student();

        // Call the enroll method
        student.enroll();

        // Call the pass method and store the result
        boolean passed = student.pass(6, 5);
        System.out.println("Pass result: " + passed);
    }
}
`}
        </code>
      </pre>

      <h2>Explanation:</h2>

      <h3>1. Class Definition:</h3>
      <pre>
        <code>
{`public class ClassCourse { ... }`}
        </code>
      </pre>
      <p>
        Defines the <code>ClassCourse</code> class. In Java, a class is a blueprint for creating objects. The <code>public</code> keyword indicates that this class can be accessed from other classes.
      </p>

      <h3>2. Inner Class Definition:</h3>
      <pre>
        <code>
{`class Student { ... }`}
        </code>
      </pre>
      <p>
        Defines an inner class <code>Student</code> within the <code>ClassCourse</code> class. Inner classes are used to group related classes together. The <code>Student</code> class has its own attributes and methods.
      </p>

      <h3>3. Attributes of Inner Class:</h3>
      <pre>
        <code>
{`String name;<br />
String address;<br />
int age;`}
        </code>
      </pre>
      <p>
        These are fields of the <code>Student</code> class that store information about the student.
      </p>

      <h3>4. Method to Enroll:</h3>
      <pre>
        <code>
{`void enroll() {<br />
    System.out.println("Student fully enrolled");<br />
}`}
        </code>
      </pre>
      <p>
        This method prints a message indicating that the student is fully enrolled. The <code>void</code> return type means it doesn’t return any value.
      </p>

      <h3>5. Method to Check Passing Status:</h3>
      <pre>
        <code>
{`boolean pass(int grade, int score) {<br />
    if (grade > score) {<br />
        System.out.println("Student passes");<br />
        return true;<br />
    } else {<br />
        System.out.println("Student fails");<br />
        return false;<br />
    }<br />
}`}
        </code>
      </pre>
      <p>
        This method checks if the student’s grade is higher than the required score. It prints whether the student passes or fails and returns a boolean value indicating the result.
      </p>

      <h3>6. Main Method:</h3>
      <pre>
        <code>
{`public static void main(String[] args) { ... }`}
        </code>
      </pre>
      <p>
        The main method is the entry point of the Java application. Java applications start executing from this method.
      </p>

      <h3>7. Creating an Instance of the Outer Class:</h3>
      <pre>
        <code>
{`ClassCourse course = new ClassCourse();`}
        </code>
      </pre>
      <p>
        Creates an instance of the <code>ClassCourse</code> class named <code>course</code>.
      </p>

      <h3>8. Creating an Instance of the Inner Class:</h3>
      <pre>
        <code>
{`Student student = course.new Student();`}
        </code>
      </pre>
      <p>
        Creates an instance of the inner class <code>Student</code> using the <code>course</code> instance of the outer class.
      </p>

      <h3>9. Calling Methods:</h3>
      <pre>
        <code>
{`student.enroll();<br />
boolean passed = student.pass(6, 5);<br />
System.out.println("Pass result: " + passed);`}
        </code>
      </pre>
      <p>
        Calls the <code>enroll</code> method on the <code>student</code> instance to enroll the student. Then, it calls the <code>pass</code> method with parameters <code>6</code> and <code>5</code>, stores the result in <code>passed</code>, and prints whether the student passed.
      </p>

      <h2>Key OOP Concepts Demonstrated:</h2>

      <h3>1. Encapsulation:</h3>
      <p>
        Encapsulation is the concept of bundling data (attributes) and methods (functions) that operate on the data into a single unit or class. In the example, the <code>Student</code> class encapsulates student-related data and methods.
      </p>

      <h3>2. Abstraction:</h3>
      <p>
        Abstraction involves hiding the complex implementation details and showing only the essential features. The <code>Student</code> class abstracts the internal workings of student enrollment and passing status, exposing only the methods to interact with these functionalities.
      </p>

      <h3>3. Classes and Objects:</h3>
      <p>
        A class is a blueprint for creating objects, and an object is an instance of a class. <code>ClassCourse</code> is the outer class, and <code>Student</code> is an inner class. <code>Student</code> objects are created based on the <code>Student</code> class blueprint.
      </p>

      <h3>4. Methods:</h3>
      <p>
        Methods define the behaviors of a class. The <code>enroll()</code> and <code>pass()</code> methods define actions related to the <code>Student</code> class.
      </p>

      <h3>5. Instantiation:</h3>
      <p>
        Instantiation is the process of creating objects from a class. Instances of both <code>ClassCourse</code> and <code>Student</code> are created in the main method.
      </p>

      <h3>6. Inner Classes:</h3>
      <p>
        Inner classes are defined within another class and can access the outer class's members. <code>Student</code> is an inner class within <code>ClassCourse</code>, illustrating logical grouping.
      </p>
      <div>
      <h1>Object-Oriented Programming (OOP) Example in Java</h1>

      <h2>Code Example:</h2>
      <pre>
        <code>
{`
import java.util.concurrent.Callable;

public class ClassCourse {
    // Inner class Student
    class Student {
        String name;
        String address;
        int age;

        // Method to enroll the student
        void enroll() {
            System.out.println("Student fully enrolled");
        }

        // Method to check if the student passes
        boolean pass(int grade, int score) {
            if (grade > score) {
                System.out.println("Student passes");
                return true;
            } else {
                System.out.println("Student fails");
                return false;
            }
        }
    }

    public static void main(String[] args) {
        // Create an instance of the outer class
        ClassCourse course = new ClassCourse();

        // Create an instance of the inner class Student
        Student student = course.new Student();

        // Call the enroll method
        student.enroll();

        // Call the pass method and store the result
        boolean passed = student.pass(6, 5);
        System.out.println("Pass result: " + passed);
    }
}
`}
        </code>
      </pre>

      <h2>Explanation:</h2>

      <h3>Class Definition:</h3>
      <p>
        <strong>public class ClassCourse &#123; ... &#125;</strong><br />
        Defines the <code>ClassCourse</code> class. In Java, a class is a blueprint for creating objects. The public keyword indicates that this class can be accessed from other classes.
      </p>

      <h3>Inner Class Definition:</h3>
      <p>
        <strong>class Student &#123; ... &#125;</strong><br />
        Defines an inner class <code>Student</code> within the <code>ClassCourse</code> class. Inner classes are used to group related classes together.
      </p>

      <h3>Attributes of Inner Class:</h3>
      <p>
        <code>String name;</code><br />
        <code>String address;</code><br />
        <code>int age;</code><br />
        These are fields of the <code>Student</code> class that store information about the student.
      </p>

      <h3>Method to Enroll:</h3>
      <p>
        <code>void enroll() &#123; ... &#125;</code><br />
        This method prints a message indicating that the student is fully enrolled.
      </p>

      <h3>Method to Check Passing Status:</h3>
      <p>
        <code>boolean pass(int grade, int score) &#123; ... &#125;</code><br />
        This method checks if the student’s grade is higher than the required score. It prints whether the student passes or fails and returns a boolean value.
      </p>

      <h3>Main Method:</h3>
      <p>
        <code>public static void main(String[] args) &#123; ... &#125;</code><br />
        The main method is the entry point of the Java application. Java applications start executing from this method.
      </p>

      <h3>Creating an Instance of the Outer Class:</h3>
      <p>
        <code>ClassCourse course = new ClassCourse();</code><br />
        Creates an instance of the <code>ClassCourse</code> class named <code>course</code>.
      </p>

      <h3>Creating an Instance of the Inner Class:</h3>
      <p>
        <code>Student student = course.new Student();</code><br />
        Creates an instance of the inner class <code>Student</code> using the <code>course</code> instance of the outer class.
      </p>

      <h3>Calling Methods:</h3>
      <p>
        <code>student.enroll();</code><br />
        Calls the <code>enroll</code> method on the <code>student</code> instance to enroll the student.
      </p>
      <p>
        <code>boolean passed = student.pass(6, 5);</code><br />
        Calls the <code>pass</code> method with parameters 6 and 5, stores the result in <code>passed</code>, and prints whether the student passed.
      </p>

      <h2>Key OOP Concepts Demonstrated:</h2>

      <h3>Object:</h3>
      <p>
        The <code>student</code> object is created from the <code>Student</code> class and can access the attributes and methods defined in that class.
      </p>

      <h3>Classes:</h3>
      <p>
        The outer class <code>ClassCourse</code> and the inner class <code>Student</code> define the structure and behavior of the objects.
      </p>

      <h3>Inheritance:</h3>
      <p>
        While inheritance is not directly used here, we could extend the <code>Student</code> class for specific student types.
      </p>

      <h3>Polymorphism:</h3>
      <p>
        Polymorphism allows method behavior to be customized for different objects by overriding methods in subclasses.
      </p>

      <h3>Abstraction:</h3>
      <p>
        The <code>Student</code> class abstracts details about how enrollment and passing status are handled, providing a simple interface.
      </p>

      <h3>Encapsulation:</h3>
      <p>
        The <code>Student</code> class encapsulates student-related data and methods, restricting direct access to its fields.
      </p>
    </div>
    </div>

    </div>
</div>
      <div className="quizBar">
                <span>Daily task</span>
                <ul>
                    <li onClick={() => handleClassClick('course1')}>Course 1</li>
                    <li onClick={() => handleClassClick('exercice1')}>Exercice 1</li>
                    <li onClick={() => handleClassClick('course2')}>Course 2</li>
                    <li onClick={() => handleClassClick('exercice2')}>Exercice 2</li>
                    <li onClick={() => handleClassClick('other')}>Other</li>
                </ul>
            </div>



    </div>
  );
};

export default WeekOneDayTwoJava;
