//src/class/learningPage.js 
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css'; // Import your CSS file

const LearningPage = () => {
  const [detailContent, setDetailContent] = useState('');
  const [weekVisibility, setWeekVisibility] = useState({});
  const [username, setUsername] = useState('');
  const [userlevel, setUserlevel] = useState('');
  const navigate = useNavigate();
  const studentAccess = parseInt(localStorage.getItem('level')) || 0; // Get student access level from localStorage

  useEffect(() => {
    // Initialize content
    setDetailContent("<h1>Welcome to your class!<br><h2>Please select your daily class from the sidebar.</h2>");

    // Initialize week visibility
    const weekElements = document.querySelectorAll('.mainWeek');
    const initialVisibility = {};
    weekElements.forEach((weekElement, index) => {
      initialVisibility[index + 1] = false;
    });
    setWeekVisibility(initialVisibility);

    // Set username
    const storedUsername = localStorage.getItem('username');
    const storedLevel = localStorage.getItem('level');
    setUsername(storedUsername || 'User');
    setUserlevel(storedLevel || 'level');
  }, []);

  const handleClassClick = async (event) => {
    event.preventDefault();
    const url = event.target.getAttribute('href');
    

    const parentWeek = event.target.closest('.weekclass').previousElementSibling;
    const weekIndex = Array.from(parentWeek.parentElement.children)
      .filter(el => el.classList.contains('mainWeek'))
      .indexOf(parentWeek) + 1;

    if (studentAccess <=weekIndex) {
      alert('You don\'t have access to this class. Please contact the administrator.');
      return;
    }

    try {
      const response = await fetch(url);
      const data = await response.text();
      setDetailContent(data);
    } catch (error) {
      console.error('Error loading content:', error);
      alert('Error loading class content.');
    }
  };

  const handleWeekClick = (weekIndex) => {
    if (weekIndex > studentAccess) {
      alert('You don\'t have access to this class. Please contact the administrator.');
      return;
    }
    setWeekVisibility(prevState => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex]
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('level');
    navigate('/login');
  };

  return (
    <div className="classPage">
      <div className="navTop">
        <nav className="navTopItem">
          <div className="navWelcom">
            <h1>Welcome, {username}</h1>
            <h2>week available {userlevel} on </h2>
            <a href="#" onClick={handleLogout}>Logout</a>
          </div>
          <ul className="navMenu">
            <li><a href="#">Home</a></li>
            <li><a href="#">Dashboard</a></li>
            <li><a href="#">Courses</a></li>
            <li><a href="#">Events</a></li>
          </ul>
        </nav>
      </div>
      <div className="mainPage">
        <div className="sideBarNav">
          <div className="sideNav">
            <h2>Course Information</h2>
            <span id="courseInfo">Math</span>
            <span>Join Workshop Here</span>
            <span className="mainWeek week1" onClick={() => handleWeekClick(1)}>Week1: JavaScript Advanced</span>
            <ul className="weekclass" style={{ display: weekVisibility[1] ? 'block' : 'none' }}>
              <li><a href="HFw1d1.html" className="dailyClass" onClick={handleClassClick}>W1 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W1 Day2: Math Variables and Operators</a></li>
              <li><a href="w1day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week2" onClick={() => handleWeekClick(2)}>Week2: JavaScript Advanced</span>
            <ul className="weekclass" style={{ display: weekVisibility[2] ? 'block' : 'none' }}>
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W2 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
          </div>
        </div>
        <div className="mainClassDetails">
          <div dangerouslySetInnerHTML={{ __html: detailContent }}></div>
        </div>
      </div>
    </div>
  );
};

export default LearningPage;


/*import React, { useEffect, useState } from 'react';
import './styles.css'; // Import your CSS file

const LearningPage = () => {
  const [detailContent, setDetailContent] = useState('');
  const [weekVisibility, setWeekVisibility] = useState({}); // To track visibility of weeks
  const studentAccess = 2; // Example access level; adjust as needed

  useEffect(() => {
    // Simulating initial content load
    setDetailContent("<h1>Welcome to your class!<br><h2>Please select your daily class from the sidebar.</h2>");
    
    // Initialize visibility state for each week
    const weekElements = document.querySelectorAll('.mainWeek');
    const initialVisibility = {};
    weekElements.forEach((weekElement, index) => {
      initialVisibility[index + 1] = false; // Hide all weeks initially
    });
    setWeekVisibility(initialVisibility);
  }, []);

  const handleClassClick = (event) => {
    event.preventDefault();
    const url = event.target.getAttribute('href');

    // Get the parent week element and determine its index
    const parentWeek = event.target.closest('.weekclass').previousElementSibling;
    const weekIndex = Array.from(parentWeek.parentElement.children)
      .filter(el => el.classList.contains('mainWeek'))
      .indexOf(parentWeek) + 1;

    //Check if the student has access to this week's class
    //if (studentAccess < weekIndex) {
    //  alert('You don\'t have access to this class. Please contact the administrator.');
     // return;
    //}

    // Fetch and display the class content
    fetch(url)
      .then(response => response.text())
      .then(data => {
        setDetailContent(data);
      })
      .catch(error => console.error('Error loading content:', error));
  };

  const handleWeekClick = (weekIndex) => {
    if (weekIndex > studentAccess) {
      alert('You don\'t have access to this class. Please contact the administrator.');
      return;
    }
    setWeekVisibility(prevState => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex] // Toggle visibility
    }));
  };

  return (
    <div className="classPage">
      <div className="navTop">
        <nav className="navTopItem">
          <div className="navWelcom">
            <h1>Welcome to your class</h1>
            <a href="#">Logout</a>
          </div>
          <ul className="navMenu">
            <li><a href="#">Home</a></li>
            <li><a href="#">Dashboard</a></li>
            <li><a href="#">Courses</a></li>
            <li><a href="#">Events</a></li>
          </ul>
        </nav>
      </div>
      <div className="mainPage">
        <div className="sideBarNav">
          <div className="sideNav">
            <h2>Course Information</h2>
            <span id="courseInfo">Math</span>
            <span>Join Workshop Here</span>
            <span className="mainWeek week1" onClick={() => handleWeekClick(1)}>Week1: JavaScript Advanced</span>
            <ul className="weekclass" style={{ display: weekVisibility[1] ? 'block' : 'none' }}>
              <li><a href="HFw1d1.html" className="dailyClass" onClick={handleClassClick}>W1 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W1 Day2: Math Variables and Operators</a></li>
              <li><a href="w1day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week2" onClick={() => handleWeekClick(2)}>Week2: JavaScript Advanced</span>
            <ul className="weekclass" style={{ display: weekVisibility[2] ? 'block' : 'none' }}>
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W2 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W2 Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week3" onClick={() => handleWeekClick(3)}>Week3: JavaScript Project</span>
            <ul className="weekclass" style={{ display: weekVisibility[3] ? 'block' : 'none' }}>
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W3 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W3 Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
          </div>
        </div>
        <div className="detailCourse">
          <div dangerouslySetInnerHTML={{ __html: detailContent }} />
        </div>
      </div>
    </div>
  );
};

export default LearningPage;
/*import React, { useEffect, useState } from 'react';
import './styles.css'; // Import your CSS file

const LearningPage = () => {
  const [detailContent, setDetailContent] = useState('');

  useEffect(() => {
    // Simulating initial content load
    setDetailContent("<h1> Welcome to your class!  <br><h2>Please select your daily class from the sidebar.</h2>");
  }, []);

  const handleClassClick = (event) => {
    event.preventDefault();
    const url = event.target.getAttribute('href');

    // Replace with actual fetch logic to load content from URL
    fetch(url)
      .then(response => response.text())
      .then(data => {
        setDetailContent(data);
      })
      .catch(error => console.error('Error loading content:', error));
  };

  return (
    <div className="classPage">
      <div className="navTop">
        <nav className="navTopItem">
          <div className="navWelcom">
            <h1>Welcome to your class</h1>
            <a href="#">Logout</a>
          </div>
          <ul className="navMenu">
            <li><a href="#">Home</a></li>
            <li><a href="#">Dashboard</a></li>
            <li><a href="#">Courses</a></li>
            <li><a href="#">Events</a></li>
          </ul>
        </nav>
      </div>
      <div className="mainPage">
        
        <div className="sideBarNav">
          <div className="sideNav">
            <h2>Course Information</h2>
            <span id="courseInfo">Math</span>
            <span>Join Workshop Here</span>
            <span className="mainWeek week1">Week1: JavaScript Advanced</span>
            <ul className="weekclass1">
              <li><a href="HFw1d1.html" className="dailyClass" onClick={handleClassClick}>W1 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W1 Day2: Math Variables and Operators</a></li>
              <li><a href="w1day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week2">Week2: JavaScript Advanced</span>
            <ul className="weekclass2">
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W2 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W2 Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week3">Week3: JavaScript Project</span>
            <ul className="weekclass3">
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W3 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W3 Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
          </div>
        </div>
        <div className="detailCourse">
          <div>
  
          </div>
          <div dangerouslySetInnerHTML={{ __html: detailContent }} />
        </div>
      </div>
    </div>
  );
};

export default LearningPage;*/
/*import React, { useEffect, useState } from 'react';
import './styles.css'; // Import your CSS file

const LearningPage = () => {
  const [detailContent, setDetailContent] = useState('');

  useEffect(() => {
    // Simulating initial content load
    setDetailContent("<h1> Welcome to your class!  <br><h2>Please select your daily class from the sidebar.</h2>");
  }, []);

  const handleClassClick = (event) => {
    event.preventDefault();
    const url = event.target.getAttribute('href');

    // Replace with actual fetch logic to load content from URL
    fetch(url)
      .then(response => response.text())
      .then(data => {
        setDetailContent(data);
      })
      .catch(error => console.error('Error loading content:', error));
  };

  return (
    <div className="classPage">
      <div className="navTop">
        <nav className="navTopItem">
          <div className="navWelcom">
            <h1>Welcome to your class</h1>
            <a href="#">Logout</a>
          </div>
          <ul className="navMenu">
            <li><a href="#">Home</a></li>
            <li><a href="#">Dashboard</a></li>
            <li><a href="#">Courses</a></li>
            <li><a href="#">Events</a></li>
          </ul>
        </nav>
      </div>
      <div className="mainPage">
        
        <div className="sideBarNav">
          <div className="sideNav">
            <h2>Course Information</h2>
            <span id="courseInfo">Math</span>
            <span>Join Workshop Here</span>
            <span className="mainWeek week1">Week1: JavaScript Advanced</span>
            <ul className="weekclass1">
              <li><a href="HFw1d1.html" className="dailyClass" onClick={handleClassClick}>W1 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W1 Day2: Math Variables and Operators</a></li>
              <li><a href="w1day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week2">Week2: JavaScript Advanced</span>
            <ul className="weekclass2">
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W2 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W2 Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
            <span className="mainWeek week3">Week3: JavaScript Project</span>
            <ul className="weekclass3">
              <li><a href="day1.html" className="dailyClass" onClick={handleClassClick}>W3 Day1: Math Intro</a></li>
              <li><a href="day2.html" className="dailyClass" onClick={handleClassClick}>W3 Day2: Math Variables and Operators</a></li>
              <li><a href="day3.html" className="dailyClass" onClick={handleClassClick}>Day3: Math Lesson 3</a></li>
              <li><a href="day4.html" className="dailyClass" onClick={handleClassClick}>Day4: Math Lesson 4</a></li>
              <li><a href="day5.html" className="dailyClass" onClick={handleClassClick}>Day5: Math Lesson 5</a></li>
            </ul>
          </div>
        </div>
        <div className="detailCourse">
          <div>
  
          </div>
          <div dangerouslySetInnerHTML={{ __html: detailContent }} />
        </div>
      </div>
    </div>
  );
};

export default LearningPage;*/