import React from 'react';
import './Footer.css';
import {useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handlegoToAdmin = () => {
    // Navigate to the LearningPage component
    navigate('/admin-login');
  };
  
  return (
    <footer className="footer">
      <div className="container5">
        <p>&copy; 2023 Code For All Bootcamp. All rights reserved. by P. Mulongo</p>
        <nav className="footer-nav">
          <a href="/">Home</a>
          <a href="./about">About</a>
          <a href="./programs">Programs</a>
          <a href="#testimonials">Testimonials</a>
          <a href="#contact">Contact</a><br></br>
          
        </nav>
        <span>Contact USA : <br></br>admin@flexacademic | papy.mulongo@flexacademic.com  <br></br>+1 980 249 5942 Charlotte north Carolina USA</span>         <p onClick={handlegoToAdmin}>admin</p>
      </div>
    </footer>
  );
};

export default Footer;