import React, { useState } from 'react';
import '../../class/learningPage.css';

const W1f1 = () => {
    // Quiz state and functions
    const [answers, setAnswers] = useState({});
    const [result, setResult] = useState('');

    const correctAnswers = {
        q1: 'b',
        q2: 'b',
        q3: 'c',
        q4: 'b',
        q5: 'a',
        q6: 'b',
        q7: 'b',
        q8: 'c',
        q9: 'c',
        q10: 'b'
    };

    const handleChange = (question, value) => {
        setAnswers({
            ...answers,
            [question]: value
        });
    };

    const checkAnswers = () => {
        let score = 0;
        const totalQuestions = Object.keys(correctAnswers).length;

        for (const [question, answer] of Object.entries(correctAnswers)) {
            if (answers[question] === answer) {
                score++;
            }
        }

        setResult(
            score === totalQuestions
                ? `Congratulations! You got all ${score} out of ${totalQuestions} correct!`
                : `You got ${score} out of ${totalQuestions} correct. Try again!`
        );
    };

    // Course and navigation state
    const [quizItem, setQuizItem] = useState('course1'); // Default to show video1 and course1

    const handleClassClick = (item) => {
        setQuizItem(quizItem === item ? '' : item); // Toggle between the clicked item and hiding all
    };

    return (
        <div className='coursePage'>
            <div className="TitleC">
                <span className="whatYouLearn">
                    <p>Welcome to Week 1 WEB FUNDAMENTAL course! This week, you will focus on learning the fundamentals of JavaScript, the last of the three core technologies of the Web.</p>
                </span>
                <h1>Welcome to Week 1 Day One Web Fundamental Course</h1>

                {quizItem === 'course1' && (
                    <div className="Video1">
                        <div className="videoContent">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/xsZuFmOczcs?si=xrfuuqpklvSVOj5z" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                        <h1>Week 1: Introduction to HTML</h1>
                        <h2>Why We Should Learn Web Fundamentals</h2>
                        <p>Web fundamentals offer a practical, accessible, and rewarding path for anyone interested in learning, practicing, and changing careers...</p>
                        {/* Add the rest of your course content here */}
                    </div>
                )}

                {quizItem === 'course2' && (
                    <div className="Video2">
                        <div className="videoContent">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/xsZuFmOczcs?si=xrfuuqpklvSVOj5z" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                        <div>
                            <p>Note for Course 2...</p>
                        </div>
                    </div>
                )}

                {quizItem === 'exercice1' && (
                    <div className='exercice1'>
                        <div className="videoContent">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/xsZuFmOczcs?si=xrfuuqpklvSVOj5z" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                        <div className="question">
                            <h3>8. What do HTML, CSS, and JavaScript do on a website?</h3>
                            <ul className="options">
                                <li className="Qoption">
                                    <input
                                        type="radio"
                                        name="q8"
                                        value="a"
                                        onChange={() => handleChange('q8', 'a')}
                                    />
                                    <span className="textradio">
                                        a. HTML structures the content, CSS styles it, and JavaScript adds interactivity
                                    </span>
                                </li>
                                <li className="Qoption">
                                    <input
                                        type="radio"
                                        name="q8"
                                        value="b"
                                        onChange={() => handleChange('q8', 'b')}
                                    />
                                    <span className="textradio">
                                        b. HTML stores images, CSS manages servers, and JavaScript connects networks
                                    </span>
                                </li>
                                <li className="Qoption">
                                    <input
                                        type="radio"
                                        name="q8"
                                        value="c"
                                        onChange={() => handleChange('q8', 'c')}
                                    />
                                    <span className="textradio">
                                        c. HTML creates hyperlinks, CSS builds browsers, and JavaScript edits text
                                    </span>
                                </li>
                                <li className="Qoption">
                                    <input
                                        type="radio"
                                        name="q8"
                                        value="d"
                                        onChange={() => handleChange('q8', 'd')}
                                    />
                                    <span className="textradio">
                                        d. HTML, CSS, and JavaScript are used to access the internet
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <button className="check-btn" onClick={checkAnswers}>
                            Check Answers
                        </button>

                        <div className="result">{result}</div>
                    </div>
                )}

                {quizItem === 'exercice2' && (
                     <div className='exercice2'>
                     <div className="videoContent">
                         <iframe width="560" height="315" src="https://www.youtube.com/embed/xsZuFmOczcs?si=xrfuuqpklvSVOj5z" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                     </div>
                     <div className="question">
                         <h3>8. What do HTML, CSS, and JavaScript do on a website?</h3>
                         <ul className="options">
                             <li className="Qoption">
                                 <input
                                     type="radio"
                                     name="q8"
                                     value="a"
                                     onChange={() => handleChange('q8', 'a')}
                                 />
                                 <span className="textradio">
                                     a. HTML structures the content, CSS styles it, and JavaScript adds interactivity
                                 </span>
                             </li>
                             <li className="Qoption">
                                 <input
                                     type="radio"
                                     name="q8"
                                     value="b"
                                     onChange={() => handleChange('q8', 'b')}
                                 />
                                 <span className="textradio">
                                     b. HTML stores images, CSS manages servers, and JavaScript connects networks
                                 </span>
                             </li>
                             <li className="Qoption">
                                 <input
                                     type="radio"
                                     name="q8"
                                     value="c"
                                     onChange={() => handleChange('q8', 'c')}
                                 />
                                 <span className="textradio">
                                     c. HTML creates hyperlinks, CSS builds browsers, and JavaScript edits text
                                 </span>
                             </li>
                             <li className="Qoption">
                                 <input
                                     type="radio"
                                     name="q8"
                                     value="d"
                                     onChange={() => handleChange('q8', 'd')}
                                 />
                                 <span className="textradio">
                                     d. HTML, CSS, and JavaScript are used to access the internet
                                 </span>
                             </li>
                         </ul>
                     </div>

                     <button className="check-btn" onClick={checkAnswers}>
                         Check Answers
                     </button>

                     <div className="result">{result}</div>
                 </div>
                )}

                {quizItem === 'other' && (
                    <div className='note1'>
                        <h1>Other Content</h1>
                    </div>
                )}
            </div>

            <div className="quizBar">
                <span>Daily task</span>
                <ul>
                    <li onClick={() => handleClassClick('course1')}>Course 1</li>
                    <li onClick={() => handleClassClick('exercice1')}>Exercice 1</li>
                    <li onClick={() => handleClassClick('course2')}>Course 2</li>
                    <li onClick={() => handleClassClick('exercice2')}>Exercice 2</li>
                    <li onClick={() => handleClassClick('other')}>Other</li>
                </ul>
            </div>
        </div>
    );
};

export default W1f1;
