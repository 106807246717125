// src/components/StateSelect.js
import React from 'react';

const states = [
  "Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", 
  "Colorado", "Connecticut", "Delaware", "District of Columbia", "Florida", 
  "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", 
  "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", 
  "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", 
  "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", 
  "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio", 
  "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", 
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", 
  "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", 
  "Wyoming", "Out of USA"
];

const StateSelect = ({ value, onChange }) => {
  return (
    <div>
     
      <select id="state" name="state" value={value} onChange={onChange}>
        <option value="">Select a state</option>
        {states.map((state, index) => (
          <option key={index} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StateSelect;