import React from 'react';
import './Header.css';
import logo from '../images/logo.jpg'; // Import the image
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handlegoToAdmin = () => {
    navigate('/admin-login');
  };

  const moveToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handlePractice = () => {
    navigate('/formPractice');
  };

  return (
    <header className="Header">
      <div className="containerheader">
        <img className="logo" src={logo} alt="logo" />
        <nav>
          <ul className="navH">
            <span className="navItem" onClick={() => moveToSection('welcome')}>Home</span>
            <span className="navItem" onClick={() => moveToSection('enroll')}>enrolls</span>
            <span className="navItem" onClick={() => moveToSection('programs')}>Programs</span>
            <span className="navItem" onClick={() => moveToSection('about')}>About Us</span>
           
            <button className='button' onClick={handleLogin}>Classroom</button>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
