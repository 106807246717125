import React from 'react';


function formPratice() {
  return (
    <div>
        sfsfsdfsfsafas
        <form>
           <div>
            <lable> Name</lable>
            <input name='name'></input>
            </div>
            <div>
            <lable> Price</lable>
            <input name='price'></input>
            </div>
            <div>
            <lable> Image</lable>
            <input name='images'></input>
            </div>

        </form>
        <table>

        </table>
    </div>
  )
}

export default formPratice